import React from "react";
import { getCookie } from "../x05Utils";

let csrftoken = getCookie('csrftoken');

const CSRFToken = () => {

    return(
        <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken}></input>
    )
}

export default CSRFToken;