import React from "react";
import axiosInstance, {getCookie} from "../../x05Utils";
import RollingButton from "../../components/RollingButton";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import CSRFToken from "../../components/CSRFToken";
import axios from "axios";
import { Helmet } from "react-helmet-async";


// Search for weather by city or zipcode


export default function WeatherSearch() {
  const [city, setCity] = React.useState("");
  const [zipCode, setZipCode] = React.useState(0);
  const [stateCode, setStateCode] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");

  const [weather, setWeather] = React.useState({});
  const [wind, setWind] = React.useState({});
  const [mainData, setMainData] = React.useState({});
  const [searchedCity, setSearchedCity] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [setLoggedIn] = useLogin();
  const [setAuthUser] = useUser();

  let csrftoken = getCookie('csrftoken')
  
  

  async function getWeather(e) {
    e.preventDefault();
    setLoading(true);

    await axios.post(
        '/api/weather/search/', {
            city: city,
            zip_code: zipCode,
            state_code: stateCode,
            country_code: countryCode
        }, {
            headers: { "Content-Type": "application/json;charset=UTF-8",
                        "X-CSRFToken": csrftoken
        }}
    ).then((res) => {
        setWeather(res.data['weather'][0]);
        setWind(res.data['wind']);
        setMainData(res.data['main']);
        setSearchedCity(res.data['name']);
        console.log(res.data);
        setLoading(false);
    });
    }


  return (
    <div id="weather-search-container">
        <Helmet>
            <title>{`X-05 | Weather Search`}</title>
            <meta name="description" content="Get real-time weather updates for any location. Search by city, ZIP code, or coordinates to stay informed about current conditions and forecasts." />
        </Helmet>
        <h1 id="weather-search-title"><i>-</i> Weather Search <i>-</i></h1>
        <hr></hr>
        <div id="weather-search-top-div">
            <div id="weather-search-daily-display">
                <h2>Daily Results</h2>
                <hr></hr>
                {searchedCity ? <legend id="weather-search-city">{searchedCity}</legend> : null}
                {weather['description'] ? <p>Description - {weather['description']}</p> : null}
                {wind['deg'] ? <p>Wind Deg - {wind['deg']}</p> : null}
                {wind['speed'] ? <p>Wind Speed - {wind['speed']}</p> : null}
                {mainData ? 
                    <div id="weather-search-daily-data">
                        <p>Temperature - {mainData['temp']}</p>
                        <p>Feels Like - {mainData['feels_like']}</p>
                        <p>Temp Min - {mainData['temp_min']}</p>
                        <p>Temp Max - {mainData['temp_max']}</p>
                        <p>Pressure - {mainData['pressure']}</p>
                        <p>Humidity - {mainData['humidity']}</p>
                    </div> 
                : null}
                {loading ? <p>Loading...</p>: null}
            </div>
            <form onSubmit={getWeather}>
                <CSRFToken></CSRFToken>
                <label for="city">City</label>
                <input type="text" name="city" placeholder="City" value={city} onChange={(e) => {
                    setCity(e.target.value)
                }}>
                </input>
                <label for="zipCode">Zip Code</label>
                <input type="text" name="zipCode" placeholder="Zip Code" value={zipCode} onChange={(e) => {
                    setZipCode(e.target.value)
                }}>
                </input>
                <label for="stateCode">State Code</label>
                <input type="text" name="stateCode" placeholder="State Code" value={stateCode} onChange={(e) => {
                    setStateCode(e.target.value)
                }}>
                </input>
                <label for="countryCode">Country Code</label>
                <input type="text" name="countryCode" placeholder="Country Code" value={countryCode} onChange={(e) => {
                    setCountryCode(e.target.value)
                }}>
                </input>
                <p>City or Zipcode is required and Statecode and Countrycode are optional.</p>
                <RollingButton type="submit" message="Search"></RollingButton>
            </form>
        </div>
        <div>
            {loading ? <p>Loading...</p> : null}
        </div>
    </div>
  )
}