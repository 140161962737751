import React, { useEffect, useState } from "react";
import axios from "axios";
import { mainUrl, getCookie } from "../../x05Utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from "react-router-dom";

import RollingButton from "../../components/RollingButton";
import { Link } from 'react-router-dom';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import CSRFToken from "../../components/CSRFToken";
import { Helmet } from "react-helmet-async";


// https://surajsharma.net/blog/axios-post-form-data
// https://github.com/CalebCurry/react/blob/main/src/pages/Register.js
export default function Register() {
    const[username, setUsername] = useState();
    const[password, setPassword] = useState();
    const[password2, setPassword2] = useState();
    const[email, setEmail] = useState();

    const location = useLocation();
    const navigate = useNavigate();

    let csrftoken = getCookie('csrftoken')

    useEffect(() => {
        localStorage.clear();
    }, []);

    function login(e) {
        e.preventDefault();
        axios({
            method: 'POST',
            url: mainUrl.concat('register/'), 
            data: JSON.stringify({
                username: username, 
                password: password, 
                password2: password2, 
                email: email
            }),
            headers: { "Content-Type": "application/json;charset=UTF-8", 
                        "X-CSRFToken": csrftoken
            }
        }).then((response) => {
            navigate(
                location?.state?.previousUrl
                    ? location.state.previousUrl
                    : '/'
            );
        })
    }

    return(
        <div id="account-register-container">
            <Helmet>
                <title>{`X-05 | Register Page`}</title>
                <meta name="description" content="Join X-05 today and unlock a world of opportunities. Register now to connect with like-minded individuals, gain valuable insights, and access exclusive content. Start your journey towards success!" />
            </Helmet>
            <h1 id="account-register-title"><i>-</i> X-05 Register <i>-</i></h1>
            <hr></hr>
            <form onSubmit={login}>
                <CSRFToken></CSRFToken>
                <label for="username"><FontAwesomeIcon icon={faUser} className="account-register-icon"/> Username</label>
                <input type="username" name="username" placeholder="" value={username} onChange={(e) => {
                    setUsername(e.target.value)
                }}>
                </input>
                <label for="email"><FontAwesomeIcon icon={faEnvelope} className="account-register-icon"/> Email</label>
                <input type="email" name="email" placeholder="" value={email} onChange={(e) => {
                    setEmail(e.target.value)
                }}>
                </input>
                <label for="password"><FontAwesomeIcon icon={faLock} className="account-register-icon"/> password</label>
                <input type="password" name="password" placeholder="" value={password} onChange={(e) => {
                    setPassword(e.target.value)
                }}>
                </input>
                <label for="password2"><FontAwesomeIcon icon={faLock} className="account-register-icon"/> Retype Password</label>
                <input type="password" name="password2" placeholder="" value={password2} onChange={(e) => {
                    setPassword2(e.target.value)
                }}>
                </input>
                <RollingButton message="Register" type="submit"></RollingButton>
                <Link to='/accounts/login/'>Already have an account?</Link>
            </form>
        </div>
    )
}