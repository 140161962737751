import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import RollingButton from "../../components/RollingButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faShield } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";


export default function FfxivEmotes() {
    
        const[emotes, setEmotes] = React.useState([]);
        const[currentPage, setCurrentPage] = React.useState(1);
        const[totalPages, setTotalPages] = React.useState(1);
    
        React.useEffect(() => {
            const getData = async () => {
                // api needs page num params
                await axiosInstance.get('ffxiv/emotes').then(res => {
                    setEmotes(res.data["Results"]);
                    setTotalPages(res.data["Pagination"]["PageTotal"]);
                    console.log(res.data["Results"]);
                    console.log(res.data["Pagination"]["PageTotal"]);
                    console.log(typeof res.data["Pagination"]["PageTotal"])
                });
            };
            getData();
        }, []);
    
        // need a function to make api call to the next page.   function is written by co-pilot
        const nextPage = async () => {
            if (currentPage < totalPages) {
                await axiosInstance.get(`ffxiv/emotes?page=${currentPage + 1}`).then(res => {
                    setEmotes(res.data["Results"]);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                });
            } else {
                console.log("No more pages");
            }
        };
    
        // need a function to make api call to the previous page.   function is written by co-pilot
        const prevPage = async () => {
            if (currentPage > 1) {
                await axiosInstance.get(`ffxiv/emotes?page=${currentPage - 1}`).then(res => {
                    setEmotes(res.data["Results"]);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                });
            } else {
                console.log("No more pages");
            }
        };
        return (
            <div id="ffxiv-emotes-container">
                <Helmet>
                    <title>{`X-05 | FFXIV Emotes`}</title>
                    <meta name="description" content="Express yourself with a wide range of FFXIV Emotes on X-05. Unleash your creativity, communicate with style, and bring your character to life. Discover the joy of emoting in FFXIV and enhance your role-playing experience!" />
                </Helmet>
                <h1 id="ffxiv-emotes-title"><i>-</i> FFXIV Emotes <i>-</i></h1>
                <hr></hr>
                <ul>
                    {emotes.map((emote, index) => {
                        return (
                            <li key={index}>
                                <img src={emote["Icon"]}></img>
                                <Link to={`/ffxiv/emote/${emote["ID"]}/`}>{emote["Name"]}</Link>
                            </li>
                        )
                    })}
                </ul>
                <hr></hr>
                <div id="ffxiv-emotes-pagination">
                    {
                        currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                    }
    
                    {
                        currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                    }
                </div>
            </div>


        )
}