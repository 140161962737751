import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../x05Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";

import RollingButton from "../../components/RollingButton";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function FfxivWeatherComponent() {
    const [weatherData, setWeatherData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const getData = async () => {
            await axiosInstance.get("ffxiv/weathers").then(res => {
                setWeatherData(res.data["Results"]);
                setTotalPages(res.data["Pagination"]["PageTotal"]);
                console.log(res.data["Results"]);
                console.log(res.data["Pagination"]["PageTotal"]);
                console.log(typeof res.data["Pagination"]["PageTotal"]);
            });
        };
        getData();
    }, []);

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance
                .get(`ffxiv/weathers?page=${currentPage + 1}`)
                .then((res) => {
                    setWeatherData(res.data["Results"]);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                });
        } else {
            console.log("No more pages");
        }
    };

    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance
                .get(`ffxiv/weathers?page=${currentPage - 1}`)
                .then((res) => {
                    setWeatherData(res.data["Results"]);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                });
        } else {
            console.log("No more pages");
        }
    }
    return (
        <div id="ffxiv-weathers-container">
            <Helmet>
                <title>{`X-05 | FFXIV Weathers`}</title>
                <meta name="description" content="Explore the dynamic weather system in FFXIV on X-05. Discover the different weather conditions, their effects, and plan your adventures accordingly. Stay prepared with our comprehensive FFXIV weather list and make the most of your time in Eorzea!" />
            </Helmet>
            <h1 id="ffxiv-weathers-title"><i>-</i> FFXIV Weathers <i>-</i></h1>
            <hr></hr>
            <ul>
                {weatherData.map((weather, index) => {
                    return (
                        <li key={index}>
                            <img src={weather["Icon"]}></img>
                            <Link to={`/ffxiv/weather/${weather["ID"]}/`}>{weather['Name']}</Link>
                        </li>
                    );
                })}
            </ul>

            <div id="ffxiv-weathers-pagination">
                {
                    currentPage > 1 ? <RollingButton
                                        click={prevPage}
                                        message="Previous"
                                    /> : null
                }
                {
                    currentPage < totalPages ? <RollingButton
                                                    click={nextPage}
                                                    message="Next"
                                                /> : null
                }

            </div>

        </div>
    );
}