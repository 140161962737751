import React from "react";
import axiosInstance from "../../x05Utils";
import RollingButton from "../../components/RollingButton";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";


export default function FfxivJobs() {
    const[jobs, setJobs] = React.useState([]);
    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);


    React.useEffect(() => {
        const getData = async () => {
            // api needs page num params
            await axiosInstance.get('ffxiv/jobs').then(res => {
                setJobs(res.data["Results"]);
                setTotalPages(res.data["Pagination"]["PageTotal"]);
                console.log(res.data["Results"]);
                console.log(res.data["Pagination"]["PageTotal"]);
                console.log(typeof res.data["Pagination"]["PageTotal"])
            });
        };
        getData();
    }, []);

    // need a function to make api call to the next page.   function is written by co-pilot
    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(`ffxiv/jobs?page=${currentPage + 1}`).then(res => {
                setJobs(res.data["Results"]);
                setCurrentPage(currentPage + 1);
                window.scrollTo(0, 0)
            });
        } else {
            console.log("No more pages");
        }
    };

    // need a function to make api call to the previous page.   function is written by co-pilot
    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(`ffxiv/jobs?page=${currentPage - 1}`).then(res => {
                setJobs(res.data["Results"]);
                setCurrentPage(currentPage - 1);
                window.scrollTo(0, 0)
            });
        } else {
            console.log("No more pages");
        }
    };
    return (
        <div id="ffxiv-jobs-container">
            <Helmet>
                <title>{`X-05 | FFXIV Jobs`}</title>
                <meta name="description" content="Unleash the power of FFXIV Jobs on X-05. Discover unique playstyles, master powerful abilities, and become a versatile adventurer. Choose your path, excel in your role, and conquer the challenges that await in the world of FFXIV!" />
            </Helmet>
            <h1 id="ffxiv-jobs-title"><i>-</i> FFXIV Jobs <i>-</i></h1>
            <hr className="ffxiv-jobs-hr"></hr>
            <ul>
                {jobs.map((job, index) => {
                    return (
                        <li key={index}>
                            <img src={job["Icon"]}></img>
                            <Link to={`/ffxiv/job/${job["ID"]}/`}>{job["Name"]}</Link>
                        </li>
                    )
                })}
            </ul>
            <hr className="ffxiv-jobs-hr"></hr>
            <div id="ffxiv-jobs-pagination">
                {
                    currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                }

                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                }
            </div>
        </div>
    )
}