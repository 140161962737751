import React from "react";
import axiosInstance from "../../x05Utils";
import { Link, useParams } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";

export default function YoutubeCategoryDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [category, setCategory] = React.useState()
    const [linkedChannels, setLinkedChannels] = React.useState([])
    const { slug } = useParams();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `youtube/category/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setCategory(res.data.category);
                    setLinkedChannels(res.data.channels);
                    console.log(res.data.category);
                }
            })
        }
        getData();
    }, [slug])
    return(
        <div id="youtube-category-detail-container">
            <Helmet>
                <title>{`X-05 | ${category?.name}`}</title>
                <meta name="description" content={`Delve into the captivating world of ${category?.name} on YouTube. Explore trending videos, popular channels, and curated content within this exciting category.`} />
            </Helmet>
            <h1 id="youtube-category-detail-title"><i>-</i> {category?.name} <i>-</i></h1>
            <hr></hr>
            <div id="youtube-category-detail-rows">
                {
                    linkedChannels?.map(channel =>
                        <Link to={`/youtube/s/channel/${channel.slug}/`} className="youtube-category-detail-card">
                            <img src={channel.image}></img>
                            <legend className="youtube-category-detail-channel-name">{channel.name}</legend>
                        </Link>
                    )
                }
            </div>
        </div>
    )
}