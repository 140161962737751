import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from "react-helmet-async";

export default function YoutubeCategoriesList() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[categories, setCategories] = React.useState()

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                'youtube/categories/'
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setCategories(res.data);
                }
            })
        }
        getData();
    }
    , [])

    return(
        <div id="youtube-categories-container">
            <Helmet>
                <title>{`X-05 | Youtube Categories`}</title>
                <meta name="description" content="Discover our diverse Youtube categories covering a wide range of topics. Explore expert insights, tips, and advice across various areas of interest. Start exploring now!" />
            </Helmet>
            <h1 id="youtube-categories-title"><i>-</i> X-05 Youtube Categories <i>-</i></h1>
            <hr></hr>
            <ul>
                {
                    categories?.map(category =>
                        <li>
                            <Link className="youtube-categories-link" to={`/youtube/category/${category.slug}/`} >
                                <FontAwesomeIcon icon={faKeyboard} className="youtube-categories-keyboard"></FontAwesomeIcon> {category.name} <FontAwesomeIcon icon={faKeyboard} className="youtube-categories-keyboard"></FontAwesomeIcon>
                            </Link>
                            <hr></hr>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}