import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import RollingButton from "../../components/RollingButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faShield } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";


export default function FfxivAchievements() {

    const[achievements, setAchievements] = React.useState([]);
    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            // api needs page num params
            await axiosInstance.get('ffxiv/achievements').then(res => {
                setAchievements(res.data["Results"]);
                setTotalPages(res.data["Pagination"]["PageTotal"]);
                console.log(res.data["Results"]);
                console.log(res.data["Pagination"]["PageTotal"]);
                console.log(typeof res.data["Pagination"]["PageTotal"])
            });
        };
        getData();
    }, []);

    // need a function to make api call to the next page.   function is written by co-pilot
    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(`ffxiv/achievements?page=${currentPage + 1}`).then(res => {
                setAchievements(res.data["Results"]);
                setCurrentPage(currentPage + 1);
                window.scrollTo(0, 0)
            });
        } else {
            console.log("No more pages");
        }
    };

    // need a function to make api call to the previous page.   function is written by co-pilot
    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(`ffxiv/achievements?page=${currentPage - 1}`).then(res => {
                setAchievements(res.data["Results"]);
                setCurrentPage(currentPage - 1);
                window.scrollTo(0, 0)
            });
        } else {
            console.log("No more pages");
        }
    };
    return (
        <div id="ffxiv-achievements-container">
            <Helmet>
                <title>{`X-05 | FFXIV Achievements`}</title>
                <meta name="description" content="Explore a comprehensive collection of FFXIV achievements on X-05. Unlock rare rewards, conquer challenges, and showcase your accomplishments. Level up your gaming journey with our extensive achievement guide!" />
            </Helmet>
            <h1 id="ffxiv-achievements-title"><i>-</i> FFXIV Achievements <i>-</i></h1>
            <hr className="ffxiv-achievements-hr"></hr>
            <ul>
                {achievements.map((achievement, index) => {
                    return (
                        <li key={index}>
                            <Link to={`/ffxiv/achievement/${achievement["ID"]}`}><FontAwesomeIcon icon={faShield} className="ffxiv-shield"></FontAwesomeIcon> {achievement["Name"]} <FontAwesomeIcon icon={faShield} className="ffxiv-shield"></FontAwesomeIcon></Link>
                        </li>
                    )
                })}
            </ul>
            <hr className="ffxiv-achievements-hr"></hr>
            <div id="ffxiv-achievements-pagination">
                {
                    currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                }

                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                }
            </div>
        </div>
    )
}