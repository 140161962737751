// Market Landing Latest
import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function MarketLandingLatest() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [landings, setLandings] = React.useState();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `market/landings/latest?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setLandings(res.data.results);

                    // the 100 is the items per page
                    // setTotalPages(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    // console.log(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    setTotalPages(Math.ceil(res.data.count / 17));
                    console.log(Math.ceil(res.data.count / 17));
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `market/landings/latest?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setLandings(res.data.results);
                    setCurrentPage(currentPage + 1);
                }
            })
        } else {
            console.log('Already on last page')
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `market/landings/latest?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setLandings(res.data.results);
                    setCurrentPage(currentPage - 1);
                }
            })
        } else {
            console.log('Already on first page')
        }
    } 

    return (
        <>
            <Helmet>
                <title>{`X-05 | Market Landing Latest`}</title>
                <meta name="description" content="Explore our comprehensive list of the latest affiliate landings on X-05. Discover exclusive partnerships, offers, and products from leading brands in their industry. Enhance your life with our curated selection of affiliate landings and unlock exciting opportunities." />
            </Helmet>
            <div id="market-landing-latest-container">
                <h1 id="market-landing-latest-title"><i>-</i> Market Landing Latest <i>-</i></h1>
                <hr></hr>
                <div id="market-landing-latest-rows">
                    {
                        landings?.map(landing => (
                            <Link className="market-landing-latest-card" to={`/market/landing/${landing?.slug}`}>
                                <img src={landing?.backup_image}></img>
                                <h2>{landing?.name.slice(0, 25)}</h2>
                            </Link>
                        ))
                    }
                </div>
                <hr></hr>
                <div id="market-landing-latest-pagination">
                    {
                        currentPage > 1 ? <RollingButton click={previousPage} message="Previous" /> : null
                    }

                    {
                        currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                    }
                </div>
            </div>
        </>
    )
}