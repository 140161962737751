import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";

export default function BlogCategoryDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [category, setCategory] = React.useState();
    const [linkedPosts, setLinkedPosts] = React.useState([]);
    const { slug } = useParams();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `blog/category/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setCategory(res.data.category.name);
                    setLinkedPosts(res.data.posts);
                    console.log(res.data.category.name);
                }
            })
        }
        getData();
    }, []);

    return (
        <div id="blog-category-detail-container">
            <Helmet>
                <title>{`X-05 | ${category}`}</title>
                <meta name="description" content={`Explore the latest articles on ${category}. Gain valuable insights, tips, and expert advice in this comprehensive collection. Stay updated with the latest trends and developments. Start reading now!`} />
            </Helmet>
            <h1 id="blog-category-detail-title"><i>-</i> {category} <i>-</i></h1>
            <hr></hr>
            <div>
                {
                    linkedPosts?.map(lpost => 
                        <Link to={`/blog/post/${lpost.slug}/`}  className="blog-category-detail-posts">
                            <img src={lpost.blog_image0}></img>
                            <div>
                                <h2 className="blog-category-detail-post-title">{lpost.title}</h2>
                                <hr></hr>
                                <p>{lpost.text_preview}</p>
                            </div>
                            <p className="blog-category-detail-post-author"><span>By {lpost.user_username}</span> <i>-</i> {lpost.date_created.slice(0, 10)}</p>
                        </Link>
                    )
                }
            </div>
        </div>
    )
}