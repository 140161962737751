import React from "react";
import axiosInstance, { mainUrl } from "../../x05Utils";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";


export default function BlogArticles() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[articles, setArticles] = React.useState();

    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);

    

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `blog/articles?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticles(res.data.results);

                    // the 100 is the items per page
                    // setTotalPages(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    // console.log(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    setTotalPages(Math.ceil(res.data.count / 17));
                    console.log(Math.ceil(res.data.count / 17));
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `blog/articles?page=${currentPage + 1}` 
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results)
                    setArticles(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages")
        }
    }
    
    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `blog/articles?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results)
                    setArticles(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages")
        }
    }

    return(
        <div id="blog-articles-list-container">
            <Helmet>
                <title>{`X-05 | Blog Articles`}</title>
                <meta name="description" content="Discover a diverse collection of insightful articles covering various topics. Stay informed and explore expert advice, tips, and trends. Start reading now!" />
            </Helmet>
            <h1 id="blog-articles-list-title"><i>-</i> X-05 Blog Articles <i>-</i></h1>
            <hr></hr>
            <div>
                {
                    articles?.map(article =>
                        <Link to={`/blog/article/${article.slug}/`} className="blog-articles-list-posts">
                            <img src={article.thumbnail}></img>
                            <div>
                                <h2 className="blog-articles-list-post-title">{article.title}</h2>
                                <hr></hr>
                                <p>{article.text_preview}</p>
                            </div>
                            <p className="blog-articles-list-post-author"><span>By {article.user_username}</span> <i>-</i> {article.date_created.slice(0, 10)}</p>
                        </Link>
                    )
                }
            </div>
            <hr></hr>
            <div id="blog-articles-list-pagination">
                {
                    currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                }

                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                }
            </div>
        </div>
    )
}