import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';

import { useLogin } from "../../contexts/LoginContext";

import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";



export default function TicketsList() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[tickets, setTickets] = React.useState()

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                'tickets/'
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setTickets(res.data);
                }
            })
        }
        getData();
    }, [])

    return(
        <div id="tickets-list-container">
            <Helmet>
                <title>{`X-05 | Tickets List`}</title>
                <meta name="description" content="Tickets list for X-05." />
            </Helmet>
            <h1 id="tickets-list-title"><i>-</i> X-05 Tickets List <i>-</i></h1>
            <hr></hr>
            <ul>
                {
                    tickets?.map(ticket =>
                        <li>
                            <Link to={`/responses/${ticket.code}/`} className="tickets-list-content">{ticket.category} - {ticket.title}</Link>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}