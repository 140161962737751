import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function YoutubeSVideosLatest() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [latestVideos, setLatestVideos] = React.useState([]);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`youtube/s/videos/latest100?page=${currentPage}`).then((res) => {
                if (res.status === 401) { 
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false); // Force refresh page
                } else {
                    setLatestVideos(res.data.results);
                    console.log(res.data.results);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 20 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 20 should be obtained through a dynamic variable
                }
            });
        }
        getData();
    }, []);

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(`youtube/s/videos/latest100?page=${currentPage + 1}`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false); // Force refresh page
                } else {
                    setLatestVideos(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0);
                }
            });
        } else {
            console.log("No more pages");
        }
    };

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(`youtube/s/videos/latest100?page=${currentPage - 1}`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false); // Force refresh page
                } else {
                    setLatestVideos(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0);
                }
            });
        } else {
            console.log("No more pages");
        }
    };

    return(
        <div id="youtube-s-videos-latest-container">
            <Helmet>
                <title>{`X-05 | YouTube Supported Videos Latest`}</title>
                <meta name="description" content="Discover the latest YouTube videos supported by X-05. Get entertained, educated, and inspired by top creators in various niches. Start exploring now!" />
            </Helmet>
            <h1 id="youtube-s-videos-latest-title"><i>-</i> Latest 100 Supported Videos <i>-</i></h1>
            <hr></hr>
            <div id="youtube-s-videos-latest-videos">
                {
                    latestVideos.map((video) => (
                        <Link to={`/youtube/s/video/${video.slug}/`} className="youtube-s-videos-latest-card">
                            <img src={video.thumbnail} alt={video.title}></img>
                            <hr></hr>
                            <h2 className="youtube-s-videos-latest-video-title">{video.title.slice(0, 15)}</h2>
                        </Link>
                    ))
                }
            </div>
            <hr></hr>
            <div id="youtube-s-videos-latest-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message="Previous" /> : null
                }
                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                }
            </div>
        </div>
    )
}