// Detail page for a story category

import React from "react";
import axiosInstance from "../../x05Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";

export default function StoryCategoryDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [category, setCategory] = React.useState();
    const [linkedStories, setLinkedStories] = React.useState([]);
    const { slug } = useParams();


    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `stories/category/${slug}`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setCategory(res.data.category.name);
                    setLinkedStories(res.data.stories);
                    console.log(res.data.category.name);
                }
            })
        };
        getData();
    }, []);

    return (
        <>
            <Helmet>
                <title>{`X-05 | Stories | ${category}`}</title>
                <meta name="description" content={`Explore the latest stories on ${category}. Gain valuable insights, tips, and expert advice in this comprehensive collection. Stay updated with the latest trends and developments. Start reading now!`} />
            </Helmet>
            <div id="stories-category-detail-container">
                <h1 id="stories-category-detail-title"><i>-</i> {category} <i>-</i></h1>
                <hr></hr>
                <div>
                    {
                        linkedStories?.map(lstory =>
                            <Link to={`/stories/short-story/${lstory.slug}/`} className="stories-category-detail-stories">
                                <img src={lstory.thumbnail}></img>
                                <div>
                                    <h2 className="stories-category-detail-story-title">{lstory.title}</h2>
                                    <hr></hr>
                                    <p>{lstory.text_preview}</p>
                                </div>
                                <p className="stories-category-detail-story-author"><span>By {lstory.user_username}</span> <i>-</i> {lstory.date_created.slice(0, 10)}</p>
                            </Link>
                        )
                    }
                </div>
            </div>
        </>
    )
}
