// both blogposts100 and blogarticles100 will contain the same styles located in a file called _blog100.scss
import React from "react";
import axiosInstance, { mainUrl } from "../../x05Utils";
import { Link } from 'react-router-dom';

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";

export default function BlogPostsLatest() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[posts, setPosts] = React.useState()

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                'blog/posts/latest100/'
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setPosts(res.data);
                }
            })
        }
        getData();
    }, [])

    return(
        <div id="blog-latest-container">
            <Helmet>
                <title>{`X-05 | Blog Posts Latest`}</title>
                <meta name="description" content="Explore our latest blog posts featuring valuable insights, tips, and trends. Stay updated with the latest developments and gain expert knowledge on various topics. Start reading now!" />
            </Helmet>
            <h1 id="blog-latest-title"><i>-</i> X-05 Blog Latest Posts <i>-</i></h1>
            <hr></hr>
            <div id="blog-latest-content-div">
                {
                    posts?.map(post =>
                        <Link to={`/blog/post/${post.slug}/`} className="blog-latest-content">
                            <img src={post.blog_image0}></img>
                            <div>
                                <h2 className="blog-latest-title">{post.title}</h2>
                                <hr></hr>
                                <p>{post.text_preview}</p>
                            </div>
                            <p className="blog-latest-author"><span>By {post.user_username}</span> <i>-</i> {post.date_created.slice(0, 10)}</p>
                        </Link>
                    )
                }
            </div>
        </div>
    )
}