import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";



export default function PokemonTCGCards() {
        
            const[pokemonTCGCards, setPokemonTCGCards] = React.useState([]);
            const[currentPage, setCurrentPage] = React.useState(1);
            const[totalPages, setTotalPages] = React.useState(1);
        
            React.useEffect(() => {
                const getData = async () => {
                    // api needs page num params
                    await axiosInstance.get(`pokemontcg/cards?page=${currentPage}`).then(res => {
                        setPokemonTCGCards(res.data["data"]);
                        setTotalPages(Math.ceil(res.data["totalCount"] / res.data["pageSize"]));
                        // console.log(res.data["data"]);
                        // console.log(Math.ceil(res.data["totalCount"] / res.data["pageSize"]));
                        // console.log(typeof res.data["Pagination"]["PageTotal"])
                    });
                };
                getData();
            }, []);
        
            // need a function to make api call to the next page.   function is written by co-pilot
            const nextPage = async () => {
                if (currentPage < totalPages) {
                    await axiosInstance.get(`pokemontcg/cards?page=${currentPage + 1}`).then(res => {
                        setPokemonTCGCards(res.data["data"]);
                        setCurrentPage(currentPage + 1);
                        window.scrollTo(0, 0)
                    });
                } else {
                    console.log("No more pages");
                }
            };
        
            // need a function to make api call to the previous page.   function is written by co-pilot
            const prevPage = async () => {
                if (currentPage > 1) {
                    await axiosInstance.get(`pokemontcg/cards?page=${currentPage - 1}`).then(res => {
                        setPokemonTCGCards(res.data["data"]);
                        setCurrentPage(currentPage - 1);
                        window.scrollTo(0, 0)
                    });
                } else {
                    console.log("No more pages");
                }
            };
            return (
                <div id="pokemontcg-cards-container">
                    <Helmet>
                        <title>{`X-05 | Pokemon TCG Cards`}</title>
                        <meta name="description" content="Explore our comprehensive list of Pokémon cards with prices. Discover rare and valuable cards, track market trends, and build your collection wisely. Stay updated with the latest card values and make informed decisions as a Pokémon card collector." />
                    </Helmet>
                    <h1 id="pokemontcg-cards-title"><i>-</i> Pokemon TCG Cards <i>-</i></h1>
                    <hr></hr>
                    <div id="pokemontcg-cards-list">
                        {pokemonTCGCards.map((pokemonTCGCard) => {
                            return (
                                <Link to={`/pokemontcg/card/${pokemonTCGCard.set.id}/${pokemonTCGCard.id}/`}>
                                    <img src={pokemonTCGCard.images.small}></img>
                                </Link>
                            )
                        })}
                    </div>
                    <hr></hr>
                    <div id="pokemontcg-cards-pagination">
                        {
                            currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                        }
        
                        {
                            currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                        }
                    </div>
                </div>
            )
        }