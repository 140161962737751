// Short Stories List Page
import React from 'react';
import axiosInstance, { mainUrl } from '../../x05Utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLogin } from '../../contexts/LoginContext';
import { useUser } from '../../contexts/UserContext';
import RollingButton from '../../components/RollingButton';
import { Helmet } from 'react-helmet-async';


export default function ShortStories() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [stories, setStories] = React.useState();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `stories/short-stories?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStories(res.data.results);

                    // the 100 is the items per page
                    // setTotalPages(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    // console.log(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    setTotalPages(Math.ceil(res.data.count / 17));
                    console.log(Math.ceil(res.data.count / 17));
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `stories/short-stories?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStories(res.data.results);
                    setCurrentPage(currentPage + 1);
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `stories/short-stories?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStories(res.data.results);
                    setCurrentPage(currentPage - 1);
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    return (
        <>
            <Helmet>
                <title>{`X-05 | Short Stories`}</title>
                <meta name="description" content="Explore a delightful collection of short stories that will captivate your imagination and emotions. Dive into a world of concise narratives, each brimming with unique characters, unexpected twists, and profound meanings. Begin your journey into the realm of brief yet powerful tales" />
            </Helmet>
            <div id="stories-short-stories-container">
                <h1 id="stories-short-stories-title"><i>-</i> X-05 Short Stories <i>-</i></h1>
                <hr></hr>
                <div>
                    {
                        stories?.map(story =>
                            <Link className="stories-short-stories-Link" to={`/stories/short-story/${story.slug}/`}>
                                <img src={story.thumbnail}></img>
                                <div>
                                    <h2 className='stories-short-stories-title'>{story.title}</h2>
                                    <hr></hr>
                                    <p>{story.text_preview}</p>
                                </div>
                                <p className='stories-short-stories-author'></p>
                            </Link>
                        )
                    }
                </div>
                <hr></hr>
                <div id="stories-short-stories-pagination">
                    {
                        currentPage > 1 ? <RollingButton click={prevPage} message="Previous"></RollingButton> : null
                    }
                    {
                        currentPage < totalPages ? <RollingButton click={nextPage} message="Next"></RollingButton> : null
                    }
                </div>
            </div>
        </>
    )
}