import React from 'react';
import axiosInstance from '../../x05Utils';
import { Link } from 'react-router-dom';
import { useLogin } from '../../contexts/LoginContext';
import { useUser } from '../../contexts/UserContext';

import RollingButton from '../../components/RollingButton';
import { Helmet } from 'react-helmet-async';


export default function BlogMarketItemArticles() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [articles, setArticles] = React.useState();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `blog/market-item-articles?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticles(res.data.results);

                    // the 100 is the items per page
                    // setTotalPages(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    // console.log(Math.ceil(res.data.count / 100)); // 100 should be obtained through a dynamic variable
                    setTotalPages(Math.ceil(res.data.count / 17));
                    console.log(Math.ceil(res.data.count / 17));
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `blog/market-item-articles?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticles(res.data.results);
                    setCurrentPage(currentPage + 1);
                }
            })
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `blog/market-item-articles?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticles(res.data.results);
                    setCurrentPage(currentPage - 1);
                }
            })
        }
    }

    return (
        <>
            <Helmet>
                <title>{`X-05 | Market Item Blog Articles`}</title>
                <meta name="description" content="X-05 Market Item Blog Articles. Stay informed and explore expert advice, tips, and trends. Start reading now!" />
            </Helmet>
            <div id="blog-market-item-articles-list-container">
                <h1 id="blog-market-item-articles-list-title"><i>-</i> Market Item Articles <i>-</i></h1>
                <hr></hr>
                <div>
                    {articles && articles.map(article => 
                        <Link to={`/blog/market-item-article/${article.slug}/`} className='blog-market-item-articles-list-posts'>
                            <img src={article.thumbnail}></img>
                            <div>
                                <h2 className='blog-market-item-articles-list-post-title'>{article.title}</h2>
                                <hr></hr>
                                <p>{article.text_preview}</p>
                            </div>
                            <p className="blog-market-item-articles-list-post-author"><span>By {article.user_username}</span> <i>-</i> {article.date_created.slice(0, 10)}</p>
                        </Link>
                    )}
                </div>
                <hr></hr>
                <div id="blog-market-item-articles-list-pagination">
                    {
                        currentPage > 1 ? <RollingButton click={previousPage} message="Previous" /> : null
                    }
                    
                    {
                        currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                    }
                </div>
            </div>
        </>
    )
}