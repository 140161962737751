import React from 'react';
import axiosInstance from '../../x05Utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

// Must add api call to button click along with sending user to url of item

export default function MarketCompanyDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [collection, setCollection] = React.useState();
    const [linkedProducts, setLinkedProducts] = React.useState([]);
    const { slug } = useParams();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `market/collection/${slug}?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setCollection(res.data.results.collection);
                    setLinkedProducts(res.data.results.products);
                    console.log(res.data.results.collection);
                    setTotalPages(Math.ceil(res.data.count / 17)); // 17 should be obtained through a dynamic variable
                }
            }
            )
        }
        getData();
    }, []);

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `market/collection/${slug}?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLinkedProducts(res.data.results.products);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `market/collection/${slug}?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLinkedProducts(res.data.results.products);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    // Special Function for making api call to analytics and sending user to url of item
    async function sendToAnalytics(e, lproduct) {
        e.preventDefault();

        // Need to send over the foriegn object of makret item
        // console.log(code);

        const newWindow = window.open(lproduct.url, '_blank');
        if (newWindow) newWindow.opener = null;

        await axiosInstance.post(
            'analytics/market-item-click/create/', {
                market_item: lproduct.code,
            }
        ).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false);
                setAuthUser(null);
                localStorage.clear();
                window.location.reload(false);
            } else {
                console.log(res.data);
            }
        }
        )
    }

    // Need function to clean item names for displaying
    const truncatedText = (text) => {
        if (text.length > 55) {
            return text.substring(0, 52) + '...';
        } else {
            return text;
        }
    }

    return (
        <>
            <Helmet>
                <title>{`${collection?.name}`}</title>
                <meta name="description" content={collection?.description.slice(0, 160)} />
            </Helmet>
            <div id="market-collection-detail-container">
                <h1 id="market-collection-detail-title"><i>-</i> {collection?.name} <i>-</i></h1>
                <hr></hr>
                <div id="market-collection-detail-sub-info">
                    <p id="market-collection-detail-description">{collection?.description}</p>
                    <img src={collection?.thumbnail}></img>
                </div>
                <hr></hr>
                <div id="market-collection-detail-products">
                    {
                        linkedProducts?.map(lproduct =>
                                <a
                                    className='market-collection-detail-card'
                                    onClick={(e) => sendToAnalytics(e, lproduct)}
                                >
                                    {
                                        lproduct?.image_url ?
                                            <img src={lproduct.image_url}></img>
                                        :
                                            <img src={lproduct.backup_image}></img>
                                    }
                                    <div className='market-collection-detail-product-div'>
                                        <p className='market-collection-detail-product-company'>{lproduct?.company_name}</p>
                                        <hr></hr>
                                    </div>
                                    <h2 className='market-collection-detail-product-name'>{truncatedText(lproduct?.name)}</h2>
                                </a>
                            )
                    }
                </div>
            </div>
            <hr></hr>
            <div id="market-collection-detail-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message="Previous" /> : null
                }
                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                }
            </div>
        </>
    )
}