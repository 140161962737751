import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../x05Utils";
import { mainUrl } from "../../x05Utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";


export default function Logout() {
    // Do normal axios call not from intercept and clear tokens/forward to home page
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const location = useLocation();
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        axiosInstance.post(mainUrl.concat('logout/'), {refresh_token: localStorage.getItem('refresh')}).then((res) => {
            console.log('clearing...')
            setLoggedIn(false);
            setAuthUser(null);
            localStorage.clear()
            // https://github.com/veryacademy/YT-Django-DRF-Simple-Blog-Series-JWT-Part-3/blob/master/react/blogapi/src/components/logout.js
            axiosInstance.defaults.headers['Authorization'] = null;
            
            navigate(
                location?.state?.previousUrl
                    ? location.state.previousUrl
                    : '/'
            )
        })
    }

    return(
        <div id="account-logout-container">
            <Helmet>
                <title>{`X-05 | Logout Page`}</title>
                <meta name="description" content="Logout from our platform securely. Safely end your session, protect your privacy, and enjoy peace of mind. Sign out and stay connected with our latest updates!" />
            </Helmet>
            <h1 id="account-logout-title"><i>-</i> X-05 Logout <i>-</i></h1>
            {authUser ? <h2 id="account-logout-goodbye">{`Goodbye ${authUser}`}</h2> : <h2 id="account-logout-goodbye"> You're not logged in.</h2>}
            {loggedIn ? <RollingButton type="button" click={handleSubmit} message="Logout"></RollingButton> : null}
        </div>
    )
}