import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";
import RollingButton from "../../components/RollingButton";

export default function ShortStoriesLatest() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [stories, setStories] = React.useState()

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    
    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `stories/short-stories/latest?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStories(res.data.results);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `stories/short-stories/latest?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStories(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0);
                }
            }
            )

        } else {
            console.log('Already on the last page')
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `stories/short-stories/latest?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStories(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0);
                }
            }
            )
            
        } else {
            console.log('Already on the first page')
        }
    }

    // Css classes are used in all latest pages for stories
    return (
        <>
            <Helmet>
                <title>X-05 | Latest Short Stories</title>
                <meta name="description" content="Explore the latest short stories on X-05. Gain valuable insights, tips, and expert advice in this comprehensive collection. Stay updated with the latest trends and developments. Start reading now!" />
            </Helmet>
            <div id="stories-latest-container">
                <h1 id="stories-latest-title"><i>-</i> Latest Short Stories <i>-</i></h1>
                <hr></hr>
                <div id="stories-latest-content-div">
                    {
                        stories?.map(story => 
                            <Link to={`/stories/short-story/${story.slug}/`} className="stories-latest-content">
                                <img src={story.thumbnail}></img>
                                <div>
                                    <h2 className="stories-latest-title">{story.title}</h2>
                                    <hr></hr>
                                    <p>{story.text_preview}</p>
                                </div>
                                <p className="stories-latest-author"><span>By {story.user_username}</span> <i>-</i> {story.date_created.slice(0, 10)}</p>
                            </Link>

                        )
                        
                    }
                </div>
                <hr></hr>
                <div id="stories-latest-pagination">
                    {
                        currentPage > 1 ? <RollingButton click={previousPage} message='Previous' /> : null
                    }
                    {
                        currentPage < totalPages ? <RollingButton click={nextPage} message='Next' /> : null
                    }
                </div>
            </div>
        </>
    )
}