import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function YoutubeSVideosList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [videos, setVideos] = React.useState()

    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `youtube/s/videos?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results)
                    setVideos(res.data.results);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 20 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 20 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `youtube/s/videos?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results)
                    setVideos(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `youtube/s/videos?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results)
                    setVideos(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    return(
        <div id="youtube-s-videos-list-container">
            <Helmet>
                <title>{`X-05 | Youtube Supported Videos List`}</title>
                <meta name="description" content="Discover the best Youtube videos supported by X-05. Get entertained, educated, and inspired by top creators in various niches. Start exploring now!" />
            </Helmet>
            <h1 id="youtube-s-videos-list-title"><i>-</i> X-05 Supported Youtube Videos List <i>-</i></h1>
            <hr></hr>
            <div id ="youtube-s-videos-list-videos">
                {
                    videos?.map(video =>
                        <Link to={`/youtube/s/video/${video.slug}/`} className="youtube-s-videos-list-card">
                            <img src={video.thumbnail}></img>
                            <hr></hr>
                            <h2 className="youtube-s-videos-list-video-title">{video.title.slice(0, 15)}</h2>
                        </Link>
                    )
                }
            </div>
            <hr></hr>
            <div id="youtube-s-videos-list-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message="Previous" /> : null
                }

                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                }
            </div>
        </div>
    )
}