import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";

import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";


export default function PollsCategoryDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [category, setCategory] = React.useState();
    const [linkedQuestions, setLinkedQuestions] = React.useState([]);
    const { slug } = useParams();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `polls/category/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setCategory(res.data.category.name);
                    setLinkedQuestions(res.data.questions);
                    console.log(res.data.category.name);
                    console.log(res.data.questions);
                }
            })
        }
        getData();
    }, []);

    return (
        <div id="polls-category-detail-container">
            <Helmet>
                <title>{`X-05 | Polls | ${category}`}</title>
                <meta name="description" content={`Explore the latest polls on ${category}. Gain valuable insights, tips, and expert advice in this comprehensive collection. Stay updated with the latest trends and developments. Start reading now!`} />
            </Helmet>

            <h1 id="polls-category-detail-title"><i>-</i> {category} <i>-</i></h1>
            <hr></hr>
            <div>
                {
                    linkedQuestions?.map(lquestion => 
                        <Link to={`/polls/question/${lquestion.slug}/`}  className="polls-category-detail-polls">
                            <h2 className="polls-category-detail-polls-title">{lquestion.question_topic}</h2>
                            <legend className="polls-category-detail-polls-date">{lquestion.publish_date.slice(0, 10)}</legend>
                            <legend className="polls-category-detail-polls-active">{lquestion.is_active === true ? "Active" : "Not Active"}</legend>
                        </Link>
                    )
                }
            </div>
        </div>
    )
}