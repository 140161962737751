import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import axios from "axios";
import { Helmet } from "react-helmet-async";

export default function Privacy() {
    const [policy, setPolicy] = React.useState();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `misc/privacy/`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setPolicy(res.data);
                }
            })
        }
        getData();
    }, [])

    // Should be its own compenent
    const renderTextWithLinksAndBreaks = (text) => {
        // Check if text was provided
        if (!text) return null;
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // const parts = text.split(urlRegex);
    
        const paragraphs = text.split('\\n');

        console.log(paragraphs);
        // loop through the paragraphs and remove any remaining \ characters
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i] = paragraphs[i].replace(/\\/g, '');
            // replace tabs with spaces here if needed
        }
        
        
    
        return paragraphs.map((paragraph, index) => (
            // we still need to remove the random \ from the text after replacing the \n characters
            // also if the text contains a link the \n characters are not replaced with <br> tags
            <React.Fragment key={index}>
                {index !== 0 && <br />} {/* Add <br> except for the first line */}
                {paragraph.split(urlRegex).map((part, partIndex) => {
                    if (part.match(urlRegex)) {
                        return (
                            <a key={partIndex} href={part} target="_blank" rel="noopener noreferrer">
                                {part}
                            </a>
                        );
                    }
                    return <span key={partIndex}>{part}</span>;
                })}
            </React.Fragment>
        ));
    };

    return (
        <>
            <Helmet>
                <title>{`X-05 Privacy Policy`}</title>
                <meta name="description" content="Discover our commitment to combat piracy and protect intellectual property on x-05.com. Read our piracy policy to understand how we ensure a safe and ethical online environment for our users and content creators. Learn about our strict measures against unauthorized distribution and the steps we take to respect digital rights. Explore our piracy policy today and be part of our responsible digital community." />
            </Helmet>
            <div id="privacy-policy-container">
                <h1 id="privacy-policy-title"><i>-</i> Privacy Policy <i>-</i></h1>
                <hr></hr>
                <div id="privacy-policy-sub-data">
                    <p id="privacy-policy-date">Policy Implemented on - {policy?.created_at.slice(0, 10)}</p>
                    <p id="privacy-policy-user">By - {policy?.user_username}</p>
                </div>
                <hr></hr>
                <div id="privacy-policy-content">
                    <pre id="privacy-policy-text">{renderTextWithLinksAndBreaks(policy?.policy)}</pre>
                </div>
            </div>
        </>
    )
}