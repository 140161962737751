import axios from "axios";

// NEEDS TO BE SET TO 'http://127.0.0.1:8001/' OR URL OF API

// IN PRODUCTION 'https://x-05.com/' AND 'https://www.x-05.com/' must be set based on where the request is coming from.

const hostProtoName = window.location.origin;
// 'http://127.0.0.1:8001/';
export const frontUrl = hostProtoName === 'https://x-05.com' || hostProtoName === 'https://www.x-05.com' ? `${hostProtoName}/` : 'http://127.0.0.1:8001/';

export const mainUrl = frontUrl.concat('api/');

// urls for 3rd party api calls
export const ffxivUrl = 'https://xivapi.com/';

// delay for api calls - https://stackoverflow.com/questions/65338219/what-is-a-good-way-to-delay-api-call
export const x05Delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// https://docs.djangoproject.com/en/4.1/howto/csrf/
// https://stackoverflow.com/questions/50732815/how-to-use-csrf-token-in-django-restful-api-and-react
export function getCookie(name) {
	let cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

let csrftoken = getCookie('csrftoken');

// USE FOR NORMAL AXIOS CALL INSTEAD OF SETTING HEADERS IN EACH FILE
export const axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        // "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjczNzY0MDEyLCJpYXQiOjE2NzM3NjM3MTIsImp0aSI6ImFhNjY5MTUxNWFkZDQ4Mzk5ODlhMTMzM2RkMGU5Mjk0IiwidXNlcl9pZCI6MSwidXNlcm5hbWUiOiJhZG1pbiJ9.xc6X8LzWE_pu0flA9iTrT-w1myx_N1nHsj2KUiKPiwQ",
    }
};


// https://www.youtube.com/watch?v=AfYfvjP1hK8&t=3580s timestamp 54:00
// https://github.com/veryacademy/YT-Django-DRF-Simple-Blog-Series-JWT-Part-3/blob/master/react/blogapi/src/axios.js
// error here it needs to be able to send multimedia or json depending on original request
const axiosInstance = axios.create({
    baseURL: mainUrl, 
    timeout: 5000, 
    headers: {
        Authorization: localStorage.getItem('access')
            ? 'Bearer ' + localStorage.getItem('access')
            : null, 
        'Content-Type': 'application/json', 
        accept: 'application/json', 
		'X-CSRFToken': csrftoken
    },
})

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			);
			localStorage.clear()
			
			console.log("interceptor hit Cors error")
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === mainUrl + 'token/refresh/'
		) {
			console.log("interceptor hit 401 on while refreshing token, please login again")
			localStorage.clear()
			window.location.href = frontUrl + 'accounts/login/';
			return Promise.reject(error);
		}

        if (
            // Handle Blacklisted tokens. Error is almost identical to a token that needs to be refreshed
			// 7/26/23 this block is being hit when it shouldn't be
            error.response.data.code === 'token_not_valid' && 
            error.response.data.detail === 'Token is blacklisted' && 
            error.response.status === 401 && 
            error.response.statusText === 'Unauthorized'
        ) {
			console.log("interceptor hit 401, token is not valid, blacklisted, or unauthorized")
			console.log(error.response)
			localStorage.clear();
            window.location.href = frontUrl + 'accounts/login/';
			return Promise.reject(error);
        }

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.data.detail !== 'Token is blacklisted' && 
			error.response.status === 401 &&
			// Firefox works with 'Unauthorized' but Chrome needs ''
			(error.response.statusText === 'Unauthorized' || error.response.statusText === '')
		) {
			// 3/8/23 bug is in this block of code
			// console.log("refreshing token")
			const refreshToken = localStorage.getItem('refresh');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				// console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					// 3/8/23 error is in this block of code -.-
					// 3/6/23 bug is in this block of code part 2
					// need to do another check here to stop mulitple requests
					return axiosInstance
						.post('token/refresh/', { refresh: refreshToken })
						.then((response) => {
							// console.log("interceptor is refreshing tokens")
                            // console.log(response.status)
                            localStorage.clear()
							// console.logs with tokens are for debugging
							// console.log(response.data.access)
							// console.log(response.data.refresh)
							//
                            localStorage.setItem('access', response.data.access);
                            localStorage.setItem('refresh', response.data.refresh);

                            axiosInstance.defaults.headers['Authorization'] =
                                'Bearer ' + response.data.access;
                            originalRequest.headers['Authorization'] =
                                'Bearer ' + response.data.access;

							// use axiosinstance or axiosmultiinstance based on originalrequest
							// console.log(originalRequest)
							return axiosInstance(originalRequest)
						})
						.catch((err) => {
							if (err.response) {
                                // Request made and server responded
                                console.log(err);
                                console.log(err.response.data);
                                console.log(err.response.status);
                                console.log(err.response.headers);
                            } else if (err.request) {
                                // The request was made but no response was received
                                console.log(err.request);
                            } else {
                                // Something else happened in setting up the request that triggered an error
                                console.log('Error', err.message)
                            }
							// ERROR THAT IS CAUSING USERS TO BE LOGGED OUT IS HERE
							// Clearing storage here is causing storage cleared on any 4** errors
							// localStorage.clear()
							// window.location.href = frontUrl + 'accounts/login/';
							return Promise.reject(error)
						});
				} else {
					// handles all other errors
					console.log('Refresh token is expired', tokenParts.exp, now);
					localStorage.clear()
					window.location.href = frontUrl + 'accounts/login/';
					return Promise.reject(error)
				}
			} else {
				console.log('Refresh token not available.');
				localStorage.clear()
				window.location.href = frontUrl + 'accounts/login/';
				return Promise.reject(error)
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);


export default axiosInstance;