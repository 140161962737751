import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function MarketCompanyList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [companies, setCompanies] = React.useState([]);
    
    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`market/companies/`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                } else {
                    setCompanies(res.data);
                    console.log(res.data);
                }
            });
        };
        getData();
    }, []);

    return (
        <div id="market-company-list-container">
            <Helmet>
                <title>{`X-05 | Market Companies`}</title>
                <meta name="description" content="Explore our comprehensive list of affiliate companies on X-05. Discover exclusive partnerships, offers, and products from leading brands in their industry. Enhance your life with our curated selection of affiliate companies and unlock exciting opportunities." />
            </Helmet>
            <h1 id="market-company-list-title"><i>-</i> Market companies <i>-</i></h1>
            <hr></hr>
            <div id="market-company-list-rows">
                {companies.map((company) => (
                    <Link className="market-company-list-card" to={`/market/company/${company?.slug}`}>
                        <img src={company?.image}></img>
                        <h2 className="market-company-list-name">{company?.name}</h2> 
                    </Link>
                ))}
            </div>
        </div>
    )
}
