import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import { faSkullCrossbones } from '@fortawesome/free-solid-svg-icons'
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function ProfileList() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[profiles, setProfiles] = React.useState([]);
    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `accounts/profiles/basic?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized');
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data);
                    setProfiles(res.data.results);
                    // console.log(res.headers);
                    setTotalPages(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `accounts/profiles/basic?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized');
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results);
                    setProfiles(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages");
        }
    };

    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `accounts/profiles/basic?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized');
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results);
                    setProfiles(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages");
        }
    };
    
    return(
        <div id="account-profile-list-container">
            <Helmet>
                <title>{`X-05 | Profiles`}</title>
                <meta name="description" content="Discover a diverse community of experts and enthusiasts on X-05. Explore profiles from various fields, gain insights, and connect with like-minded individuals. Join X-05 today!" />
            </Helmet>
            <h1 id="account-profile-list-title"><i>-</i> X-05 Profiles <i>-</i></h1>
            <hr></hr>
            {
                loggedIn ? 
                        <div>
                            <div id="account-profile-list-rows">
                                {
                                    profiles?.map(profile =>
                                        <Link to={`/accounts/profile/${profile.slug}`} className="account-profile-list-card">
                                            <img src={profile.thumb_image}></img>
                                            <legend className="account-profile-list-username">{profile.user_username.slice(0, 7)}</legend>
                                            <legend className="account-profile-list-rank">{profile.rank}</legend>
                                        </Link>
                                    )
                                }
                            </div>
                            <hr></hr>
                            <div id="account-profile-list-pagination">
                                {
                                    currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                                }
                
                                {
                                    currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                                }
                            </div>
                        </div>
                :
                    <p><FontAwesomeIcon icon={faSkullCrossbones}></FontAwesomeIcon> You're not logged in</p>
            }
        </div>
    )
}