import React from "react";
import axios from "axios";
import { axiosConfig, mainUrl } from "../../x05Utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHandPointRight, faHandPointLeft, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function FfxivHome() {
    const[ffxiv, setFfxiv] = React.useState([]);

    React.useEffect(() => {
        const getData = async () => {
            await axios.get(mainUrl.concat('ffxiv/'), axiosConfig).then(res => {
                setFfxiv(res.data)
            });
        };
        getData();
    }, []);

    return (
        <div id="ffxiv-home-container">
            <Helmet>
                <title>{`X-05 | FFXIV Home`}</title>
                <meta name="description" content="Discover a comprehensive collection of FFXIV resources on X-05. Explore our diverse collection of guides, tools, and more. Level up your gaming journey with our extensive FFXIV guide!" />
            </Helmet>
            <h1 id="ffxiv-home-title"><i>-</i> FFXIV Home <i>-</i></h1>
            <hr id="ffxiv-home-hr"></hr>
            <ul id="ffxiv-home-nav">
                <li>
                    <Link to="/ffxiv/servers/">
                        <RollingButton message="Servers"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/updates/">
                        <RollingButton message="Updates"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/maintenance/">
                        <RollingButton message="Maintenance"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/titles/">
                        <RollingButton message="Titles"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/achievements/">
                        <RollingButton message="Achievements"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/craftactions/">
                        <RollingButton message="Craft Actions"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/actions/">
                        <RollingButton message="Actions"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/jobs/">
                        <RollingButton message="Jobs"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/emotes/">
                        <RollingButton message="Emotes"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/pets/">
                        <RollingButton message="Pets"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/companions/">
                        <RollingButton message="Companions"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/ffxiv/weathers/">
                        <RollingButton message="Weathers"></RollingButton>
                    </Link>
                </li>
            </ul>
            <div id="ffxiv-home-div">
                {
                    Object.keys(ffxiv).map(key => {
                        return(
                            <div className="ffxiv-home-card">
                                <h2><FontAwesomeIcon icon={faNoteSticky} className="ffxiv-sticky-note"/> Recent {key}</h2>
                                <hr></hr>
                                <div>
                                    {
                                        Object.values(ffxiv[key]).map(nvalue => {
                                            return(
                                                <div className="ffxiv-home-inner-div">
                                                    <a href={nvalue.url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faHandPointRight} className="ffxiv-point"/> {nvalue.title} <FontAwesomeIcon icon={faHandPointLeft} className="ffxiv-point"/></a>
                                                    <legend>{nvalue.time.slice(0, 10)}</legend>
                                                    <hr></hr>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}