import React from "react";
import axios from "axios";
import { axiosConfig, mainUrl } from "../../x05Utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from "react-helmet-async";



export default function FfxivServers() {
    const[servers, setServers] = React.useState([]);

    React.useEffect(() => {
        const getData = async () => {
            await axios.get(mainUrl.concat('ffxiv/servers/'), axiosConfig).then(res => {
                setServers(res.data);
            });
        };
        getData();
    }, []);

    return (
        <div id="ffxiv-servers-container">
            <Helmet>
                <title>{`X-05 | FFXIV Servers`}</title>
                <meta name="description" content="Discover the complete FFXIV Servers List on X-05. Choose your server, join a vibrant community, and embark on epic adventures. Find the perfect home to start your journey in the immersive world of FFXIV!" />
            </Helmet>
            <h1 id="ffxiv-servers-title"><i>-</i> FFXIV Servers <i>-</i></h1>
            <hr id="ffxiv-title-hr"></hr>
            <div id="ffxiv-server-div"> 
                {
                    Object.keys(servers).map(datacenter => {
                        return(
                            <div className="ffxiv-server-card">
                                <h2 className="ffxiv-datacenter-title"><FontAwesomeIcon icon={faFlask} className="ffxiv-flask"/> {datacenter} <FontAwesomeIcon icon={faFlask} className="ffxiv-flask"/></h2>
                                <hr className="ffxiv-server-hr"></hr>
                                <ul className="ffxiv-server-list">
                                    {
                                        servers[datacenter].map(item => {
                                            return(<li className="ffxiv-server"><i>-</i> {item} <i>-</i></li>)
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}