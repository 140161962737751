import './App.css';

import PollsQuestions from './pages/polls/PollsQuestions';
import PollsHome from './pages/polls/PollsHome';
import PollsCategories from './pages/polls/PollsCategories';

import PollsQuestionDetail from './pages/polls/PollsQuestionDetail';
import PollsCategoryDetail from './pages/polls/PollsCategoryDetail';

import FfxivHome from './pages/ffxiv/FfxivHome';
import Home from './pages/misc/Home';
import BlogHome from './pages/blog/BlogHome';
import MarketHome from './pages/market/MarketHome';

import BlogPostCreate from './pages/blog/BlogPostCreate';
import BlogPostDetail from './pages/blog/BlogPostDetail';
import BlogPostUpdate from './pages/blog/BlogPostUpdate';
import BlogCategoryDetail from './pages/blog/BlogCategoryDetail';
import BlogCategories from './pages/blog/BlogCategories';
import BlogArticles from './pages/blog/BlogArticles';
import BlogArticleDetail from './pages/blog/BlogArticleDetail';
import BlogArticlesLatest from './pages/blog/BlogArticlesLatest';
import BlogPostsLatest from './pages/blog/BlogPostsLatest';

import X05NavBar from './components/NavBar';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';

import FfxivServers from './pages/ffxiv/FfxivServers';
import FfxivUpdates from './pages/ffxiv/FfxivUpdates';
import FfxivMaintenance from './pages/ffxiv/FfxivMaintenance';
import Register from './pages/accounts/Register';
import Login from './pages/accounts/Login';
import Logout from './pages/accounts/Logout';
import ProfileList from './pages/accounts/ProfileList';
import ProfileDetail from './pages/accounts/ProfileDetail';
import ProfileUpdate from './pages/accounts/ProfileUpdate';

import FfxivTitles from './pages/ffxiv/FfxivTitles';
import FfxivAchievements from './pages/ffxiv/FfxivAchievements';
import FfxivCraftActions from './pages/ffxiv/FfxivCraftActions';
import FfxivActions from './pages/ffxiv/FfxivActions';
import FfxivTitle from './pages/ffxiv/FfxivTitleDetail';
import FfxivAction from './pages/ffxiv/FfxivActionDetail';
import FfxivJobs from './pages/ffxiv/FfxivJobs';
import FfxivJob from './pages/ffxiv/FfxivJobDetail';
import FfxivPets from './pages/ffxiv/FfxivPets';
import FfxivEmotes from './pages/ffxiv/FfxivEmotes';
import FfxivCompanions from './pages/ffxiv/FfxivCompanions';
import WeatherSearch from './pages/weather/WeatherSearch';
import PokemonTCGCards from './pages/pokemontcg/PokemonTCGCards';
import PokemonTCGCard from './pages/pokemontcg/PokemonTCGCard';
import YugiohTCGCards from './pages/yugiohtcg/YugiohTCGCards';
import FfxivWeatherComponent from './pages/ffxiv/FfxivWeathers';
import RecipesRandom from './pages/recipes/RecipesRandom';
import RecipesDetail from './pages/recipes/RecipesDetail';
import RecipesSearch from './pages/recipes/RecipesSearch';
import RecipesList from './pages/recipes/RecipesList';
import MarketCompanyList from './pages/market/MarketCompanyList';
import MarketCompanyDetail from './pages/market/MarketCompanyDetail';
import MarketItemList from './pages/market/MarketItemList';
import MarketCollectionList from './pages/market/MarketCollectionList';
import MarketCollectionDetail from './pages/market/MarketCollectionDetail';
import MarketItemDetail from './pages/market/MarketItemDetail';
import MarketLandingList from './pages/market/MarketLandingList';
import MarketLandingDetail from './pages/market/MarketLandingDetail';
import MarketLandingLatest from './pages/market/MarketLandingLatest';
import TicketsList from './pages/tickets/TicketsList';
import TicketResponsesList from './pages/tickets/TicketResponsesList';

import TicketResponseDetail from './pages/tickets/TicketResponseDetail';
import TicketCreate from './pages/tickets/TicketCreate';
import YoutubeSChannelsLatest from './pages/youtube/YoutubeSChannelsLatest';
import YoutubeSVideoDetail from './pages/youtube/YoutubeSVideoDetail';
import YoutubeSVideosLatest from './pages/youtube/YoutubeSVideosLatest';
import YoutubeCategoriesList from './pages/youtube/YoutubeCategoriesList';
import YoutubeCategoryDetail from './pages/youtube/YoutubeCategoryDetail';
import YoutubeSHome from './pages/youtube/YoutubeSHome';
import YoutubeSChannelsList from './pages/youtube/YoutubeSChannelsList';
import YoutubeSVideosList from './pages/youtube/YoutubeSVideosList';
import YoutubeSChannelDetail from './pages/youtube/YoutubeSChannelDetail';
import BlogPosts from './pages/blog/BlogPosts';
import EmailListSubscriberCreate from './pages/emaillist/EmailListSubscriberCreate';
import EmailListSubscriberAlert from './alerts/EmailListSubscriberAlert';

import ShortStories from './pages/stories/ShortStories';
import ShortStoriesLatest from './pages/stories/ShortStoriesLatest';
import ShortStoryDetail from './pages/stories/ShortStoryDetail';
import StoryCategories from './pages/stories/StoryCategories';
import StoryCategoryDetail from './pages/stories/StoryCategoryDetail';

import Privacy from './pages/misc/Privacy';
import BlogMarketItemArticles from './pages/blog/BlogMarketItemArticles';
import BlogMarketItemArticleDetail from './pages/blog/BlogMarketItemArticleDetail';
import BlogMarketItemArticlesLatest from './pages/blog/BlogMarketItemArticlesLatest';
import BlogMarketLandingArticles from './pages/blog/BlogMarketLandingArticles';
import BlogMarketLandingArticleDetail from './pages/blog/BlogMarketLandingArticleDetail';
import BlogMarketLandingArticlesLatest from './pages/blog/BlogMarketLandingArticlesLatest';
import MarketItemLatest from './pages/market/MarketItemLatest';
import About from './pages/misc/About';



import { LoginProvider } from './contexts/LoginContext';
import { UserProvider } from './contexts/UserContext';


function App() {
  
  return (
    <div className="App">
      <LoginProvider>
        <UserProvider>
          
          <X05NavBar></X05NavBar>
          <EmailListSubscriberAlert></EmailListSubscriberAlert>
          
          <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='/privacy/' element={<Privacy></Privacy>}></Route>
            <Route path='/about/' element={<About></About>}></Route>

            <Route path='/accounts/register/' element={<Register></Register>}></Route>
            <Route path='/accounts/login/' element={<Login></Login>}></Route>
            <Route path='/accounts/logout/' element={<Logout></Logout>}></Route>
            <Route path='/accounts/profiles/' element={<ProfileList></ProfileList>}></Route>
            <Route path='/accounts/profile/:slug/' element={<ProfileDetail></ProfileDetail>}></Route>
            <Route path='/accounts/profile/update/:slug/' element={<ProfileUpdate></ProfileUpdate>}></Route>

            <Route path='/polls/' element={<PollsHome></PollsHome>}></Route>
            <Route path='/polls/questions/' element={<PollsQuestions></PollsQuestions>}></Route>
            <Route path='/polls/question/:slug/' element={<PollsQuestionDetail></PollsQuestionDetail>}></Route>
            <Route path='/polls/categories/' element={<PollsCategories></PollsCategories>}></Route>
            <Route path='/polls/category/:slug/' element={<PollsCategoryDetail></PollsCategoryDetail>}></Route>

            <Route path='/blog/' element={<BlogHome></BlogHome>}></Route>
            <Route path='/blog/categories/' element={<BlogCategories></BlogCategories>}></Route>
            <Route path='/blog/posts/' element={<BlogPosts></BlogPosts>}></Route>
            <Route path='/blog/post/:slug/' element={<BlogPostDetail></BlogPostDetail>}></Route>
            <Route path='/blog/post/update/:slug/' element={<BlogPostUpdate></BlogPostUpdate>}></Route>
            <Route path='/blog/category/:slug/' element={<BlogCategoryDetail></BlogCategoryDetail>}></Route>
            <Route path='/blog/posts/create/' element={<BlogPostCreate></BlogPostCreate>}></Route>
            <Route path='/blog/articles/' element={<BlogArticles></BlogArticles>}></Route>
            <Route path='/blog/article/:slug/' element={<BlogArticleDetail></BlogArticleDetail>}></Route>
            <Route path='/blog/articles/latest/' element={<BlogArticlesLatest></BlogArticlesLatest>}></Route>
            <Route path='/blog/posts/latest/' element={<BlogPostsLatest></BlogPostsLatest>}></Route>
            <Route path='/blog/market-item-articles/' element={<BlogMarketItemArticles></BlogMarketItemArticles>}></Route>
            <Route path='/blog/market-item-articles/latest/' element={<BlogMarketItemArticlesLatest></BlogMarketItemArticlesLatest>}></Route>
            <Route path='/blog/market-item-article/:slug/' element={<BlogMarketItemArticleDetail></BlogMarketItemArticleDetail>}></Route>
            <Route path='/blog/market-landing-articles/' element={<BlogMarketLandingArticles></BlogMarketLandingArticles>}></Route>
            <Route path='/blog/market-landing-articles/latest/' element={<BlogMarketLandingArticlesLatest></BlogMarketLandingArticlesLatest>}></Route>
            <Route path='/blog/market-landing-article/:slug/' element={<BlogMarketLandingArticleDetail></BlogMarketLandingArticleDetail>}></Route>

            <Route path='/ffxiv/' element={<FfxivHome></FfxivHome>}></Route>
            <Route path='/ffxiv/servers/' element={<FfxivServers></FfxivServers>}></Route>
            <Route path='/ffxiv/updates/' element={<FfxivUpdates></FfxivUpdates>}></Route>
            <Route path='/ffxiv/maintenance/' element={<FfxivMaintenance></FfxivMaintenance>}></Route>
            <Route path='/ffxiv/emotes/' element={<FfxivEmotes></FfxivEmotes>}></Route>
            <Route path='/ffxiv/pets/' element={<FfxivPets></FfxivPets>}></Route>
            <Route path='/ffxiv/titles/' element={<FfxivTitles></FfxivTitles>}></Route>
            <Route path='/ffxiv/achievements/' element={<FfxivAchievements></FfxivAchievements>}></Route>
            <Route path='/ffxiv/craftactions/' element={<FfxivCraftActions></FfxivCraftActions>}></Route>
            <Route path='/ffxiv/actions/' element={<FfxivActions></FfxivActions>}></Route>
            <Route path='/ffxiv/jobs/' element={<FfxivJobs></FfxivJobs>}></Route>
            <Route path='/ffxiv/companions/' element={<FfxivCompanions></FfxivCompanions>}></Route>
            <Route path='/ffxiv/title/:id/' element={<FfxivTitle></FfxivTitle>}></Route>
            <Route path='/ffxiv/action/:id/' element={<FfxivAction></FfxivAction>}></Route>
            <Route path='/ffxiv/job/:id/' element={<FfxivJob></FfxivJob>}></Route>
            
            <Route path='/ffxiv/weathers/' element={<FfxivWeatherComponent></FfxivWeatherComponent>}></Route>

            <Route path='/weather/search/' element={<WeatherSearch></WeatherSearch>}></Route>

            <Route path='/pokemontcg/cards/' element={<PokemonTCGCards></PokemonTCGCards>}></Route>
            <Route path='/pokemontcg/card/:set_id/:card_id/' element={<PokemonTCGCard></PokemonTCGCard>}></Route>

            <Route path='/yugiohtcg/cards/' element={<YugiohTCGCards></YugiohTCGCards>}></Route>
            
            <Route path='/recipes/' element={<RecipesSearch></RecipesSearch>}></Route>
            <Route path='/recipes/random/:search_str/' element={<RecipesRandom></RecipesRandom>}></Route>
            <Route path='/recipes/recipe/:id/' element={<RecipesDetail></RecipesDetail>}></Route>
            <Route path='/recipes/list/:search_str/' element={<RecipesList></RecipesList>}></Route>

            <Route path='/market/' element={<MarketHome></MarketHome>}></Route>
            <Route path='/market/companies/' element={<MarketCompanyList></MarketCompanyList>}></Route>
            <Route path='/market/company/:slug/' element={<MarketCompanyDetail></MarketCompanyDetail>}></Route>
            
            <Route path='/market/items/' element={<MarketItemList></MarketItemList>}></Route>
            <Route path='/market/items/latest/' element={<MarketItemLatest></MarketItemLatest>}></Route>
            <Route path='/market/collections/' element={<MarketCollectionList></MarketCollectionList>}></Route>
            <Route path='/market/collection/:slug/' element={<MarketCollectionDetail></MarketCollectionDetail>}></Route>

            <Route path='/market/landings/' element={<MarketLandingList></MarketLandingList>}></Route>
            <Route path='/market/landings/latest/' element={<MarketLandingLatest></MarketLandingLatest>}></Route>
            <Route path='/market/landing/:slug/' element={<MarketLandingDetail></MarketLandingDetail>}></Route>

            <Route path='/tickets/' element={<TicketsList></TicketsList>}></Route>
            <Route path='/ticket/responses/:ticket_code/' element={<TicketResponsesList></TicketResponsesList>}></Route>
            <Route path='/ticket/response/:code/' element={<TicketResponseDetail></TicketResponseDetail>}></Route>
            <Route path='/ticket/create/' element={<TicketCreate></TicketCreate>}></Route>

            <Route path='/youtube/s/channels/latest/' element={<YoutubeSChannelsLatest></YoutubeSChannelsLatest>}></Route>
            <Route path='/youtube/s/video/:slug/' element={<YoutubeSVideoDetail></YoutubeSVideoDetail>}></Route>
            <Route path='/youtube/s/videos/latest/' element={<YoutubeSVideosLatest></YoutubeSVideosLatest>}></Route>
            
            <Route path='/youtube/categories/' element={<YoutubeCategoriesList></YoutubeCategoriesList>}></Route>
            <Route path='/youtube/category/:slug/' element={<YoutubeCategoryDetail></YoutubeCategoryDetail>}></Route>
            <Route path='/youtube/s/' element={<YoutubeSHome></YoutubeSHome>}></Route>
            <Route path='/youtube/s/channels/' element={<YoutubeSChannelsList></YoutubeSChannelsList>}></Route>
            <Route path='/youtube/s/videos/' element={<YoutubeSVideosList></YoutubeSVideosList>}></Route>
            <Route path='/youtube/s/channel/:slug/' element={<YoutubeSChannelDetail></YoutubeSChannelDetail>}></Route>

            <Route path='/email-list/subscriber/create/' element={<EmailListSubscriberCreate></EmailListSubscriberCreate>}></Route>

            <Route path='/stories/short-stories/' element={<ShortStories></ShortStories>}></Route>
            <Route path='/stories/short-stories/latest/' element={<ShortStoriesLatest></ShortStoriesLatest>}></Route>
            <Route path='/stories/short-story/:slug/' element={<ShortStoryDetail></ShortStoryDetail>}></Route>
            <Route path='/stories/categories/' element={<StoryCategories></StoryCategories>}></Route>
            <Route path='/stories/category/:slug/' element={<StoryCategoryDetail></StoryCategoryDetail>}></Route>

          </Routes>
        </UserProvider>
      </LoginProvider>
    </div>
  );
}


export default App;
