import React, { useContext } from "react";
import axiosInstance from "../../x05Utils";
import { useParams, Link } from "react-router-dom";
import RollingButton from "../../components/RollingButton";
import { useLogin } from "../../contexts/LoginContext";

import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";

export default function ProfileDetail() {
    const [profileInfo, setProfileInfo] = React.useState();
    const { slug } = useParams();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    
    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `accounts/profile/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setProfileInfo(res.data);
                    console.log(res.data)
                }
            })
        }
        getData();
    }, [])

    return(
        <div id="account-profile-detail-container">
            <Helmet>
                <title>{`X-05 | ${profileInfo?.user_username}`}</title>
                <meta name="description" content={`${profileInfo?.user_username}'s profile on X-05. Discover their insights, expertise, and engaging content. Stay connected and get inspired. Join the X-05 community today!`} />
            </Helmet>

            <img src={profileInfo?.banner_image} id="account-profile-detail-banner"></img>
            
            <div id="account-profile-detail-info">
                <div id="account-profile-detail-card">
                    <img src={profileInfo?.thumb_image} id="account-profile-detail-thumb"></img>
                    <div>
                        <h1>{profileInfo?.user_username}</h1>
                        <legend id="account-profile-detail-rank">{profileInfo?.rank}</legend>
                    </div>
                </div>
                {
                    authUser == profileInfo?.user_pk ? 
                        <Link to={`/accounts/profile/update/${profileInfo?.slug}/`}>
                            <RollingButton message="Update"></RollingButton>
                        </Link>
                    :
                        null
                }
            </div>
            
            <p id="account-profile-detail-bio">{profileInfo?.bio}</p>
        </div>
    )
}