import React, { useContext, useState, createContext } from 'react'



const LoginContext = createContext();

export function useLogin() {
    return useContext(LoginContext)
}

export function LoginProvider({children}) {
    const [loggedIn, setLoggedIn] = useState(
        localStorage.access ? true : false
    )
      
    function changeLoggedIn(value) {
        setLoggedIn(value);
        if (value === false) {
            localStorage.clear();
        }
    }

    return (
        <LoginContext.Provider value={[loggedIn, changeLoggedIn]}>
            {children}
        </LoginContext.Provider>
    )

}


