import React from "react";
import RollingButton from "../../components/RollingButton";
import axiosInstance from "../../x05Utils";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function FfxivAction() {
    const [action, setAction] = React.useState({});
    const { id } = useParams(); // returning undefined

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`ffxiv/action?id=${id}`).then(res => {
                setAction(res.data['ActionCategory']);
                console.log(res.data);
            });
        };
        getData();
    }, [id]);

    return (
        <div id="ffxiv-action-container">
            <Helmet>
                <title>{`X-05 | ${action['Name']}`}</title>
                <meta name="description" content={`Learn more about the FFXIV Action ${action['Name']} on X-05. Discover its effects, cooldown, and more. Level up your gaming journey with our extensive action guide!`} />
            </Helmet>
            <h1 id="ffxiv-action-title"><i>-</i> Action Details <i>-</i></h1>
            <hr className="ffxiv-action-hr"></hr>
            <div id="ffxiv-action-content">
                <h2>{action['Name']}</h2>
            </div>
        </div>
    )
}  