// both blogpostsLatest and blogarticlesLatest will contain the same styles located in a file called _bloglatest.scss

import React from "react";
import axiosInstance, { mainUrl } from "../../x05Utils";
import { Link } from 'react-router-dom';

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";
import RollingButton from "../../components/RollingButton";

export default function BlogArticlesLatest() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[articles, setArticles] = React.useState()

    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `blog/articles/latest100?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticles(res.data.results);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `blog/articles/latest100?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticles(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0);
                }
            }
            )
        } else {
            console.log('No more pages')
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `blog/articles/latest100?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticles(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0);
                }
            }
            )
        } else {
            console.log('No more pages')
        }
    }


    return(
        <div id="blog-latest-container">
            <Helmet>
                <title>{`X-05 | Blog Articles Latest`}</title>
                <meta name="description" content="Explore our latest blog articles featuring valuable insights, tips, and trends. Stay updated with the latest developments and gain expert knowledge on various topics. Start reading now!" />
            </Helmet>
            <h1 id="blog-latest-title"><i>-</i> X-05 Blog Latest Articles <i>-</i></h1>
            <hr></hr>
            <div id="blog-latest-content-div">
                {
                    articles?.map(article =>
                        <Link to={`/blog/article/${article.slug}/`} className="blog-latest-content">
                            <img src={article.thumbnail}></img>
                            <div>
                                <h2 className="blog-latest-title">{article.title}</h2>
                                <hr></hr>
                                <p>{article.text_preview}</p>
                            </div>
                            <p className="blog-latest-author"><span>By {article.user_username}</span> <i>-</i> {article.date_created.slice(0, 10)}</p>
                        </Link>
                    )
                }
            </div>
            <hr></hr>
            <div id="blog-latest-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message={"Previous"}>Previous Page</RollingButton> : null
                }
                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}>Next Page</RollingButton> : null
                }
            </div>
        </div>
    )
}