import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faDragon, faPoll, faUserAstronaut, faStore, faWandMagicSparkles, faSkull, faIdCard, faBowlFood, faShop, faKeyboard, faBook} from '@fortawesome/free-solid-svg-icons';

import { useLogin } from '../contexts/LoginContext';
import { useUser } from '../contexts/UserContext';
import React from 'react';
import axiosInstance from "../x05Utils";
import { useParams } from "react-router-dom";


export default function X05NavBar() {

  const [loggedIn, setLoggedIn] = useLogin();
  const [authUser, setAuthUser] = useUser();
  const [profileInfo, setProfileInfo] = React.useState();
  const [defaultImage, setDefaultImage] = React.useState();

  React.useEffect(() => {
    const getUserData = async () => {
      try {
        const res = await axiosInstance.get(`accounts/profile/basic/by-user/`);
        if (res.status === 401) {
          setLoggedIn(false);
          setAuthUser(null);
          localStorage.clear();
          window.location.reload(false);
        } else {
          setProfileInfo(res.data);
          console.log(res.data);
        }
      } catch (error) {
        console.error("Error in getUserData:", error);
      }
    };
  
    const getDefaultData = async () => {
      try {
        await getUserData(); // Call getUserData before getDefaultData
        const res = await axiosInstance.get(`misc/default-image/thumbnail/39tbf1Y3PPs8g7wgkx4K/`);
        if (res.status === 401) {
          setLoggedIn(false);
          setAuthUser(null);
          localStorage.clear();
          window.location.reload(false);
        } else {
          setDefaultImage(res.data);
          console.log(res.data);
        }
      } catch (error) {
        console.error("Error in getDefaultData:", error);
      }
    }
  
    getDefaultData();
  }, []);

  return (
    <Navbar id='nav' expand="lg">
      <Container fluid>
        <Navbar.Brand id="brand" as={Link} to="/" ><FontAwesomeIcon icon={faBalanceScale} id="nav-brand-icon"/> X-05</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link className="nav-links" as={Link} to="/blog/" ><FontAwesomeIcon icon={faDragon} className="nav-icon"/> Blog</Nav.Link>
            <Nav.Link className="nav-links" as={Link} to="/recipes/" ><FontAwesomeIcon icon={faBowlFood} className="nav-icon"/> Recipes</Nav.Link>
            <Nav.Link className="nav-links" href='https://www.etsy.com/shop/ProjectX05' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faStore} className="nav-icon"/> Store</Nav.Link>
            <Nav.Link className="nav-links" as={Link} to="/market/" ><FontAwesomeIcon icon={faShop} className="nav-icon"/> Market</Nav.Link>
            <NavDropdown title="Link" id="drop-down">
              <NavDropdown.Item className='drop-links' as={Link} to="/polls/categories/"><FontAwesomeIcon icon={faPoll}/> Polls</NavDropdown.Item>
              <NavDropdown.Item  className='drop-links' as={Link} to="/ffxiv/">
              <FontAwesomeIcon icon={faWandMagicSparkles}/> FFXIV
              </NavDropdown.Item>
              <NavDropdown.Item className='drop-links' as={Link} to="/pokemontcg/cards/"><FontAwesomeIcon icon={faIdCard}/> Pokemon TCG</NavDropdown.Item>
              <NavDropdown.Divider className='drop-divider'/>
              <NavDropdown.Item  className='drop-links' as={Link} to="/accounts/profiles/">
                <FontAwesomeIcon icon={faSkull}/> Profiles
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="nav-links" as={Link} to="/weather/search/" >
            <FontAwesomeIcon icon={faUserAstronaut} className="nav-icon"/> Weather
            </Nav.Link>
            <Nav.Link className='nav-links' as={Link} to="/youtube/s/">
              <FontAwesomeIcon icon={faKeyboard} className="nav-icon"/> Youtube
            </Nav.Link>
            <Nav.Link className='nav-links' as={Link} to="/stories/short-stories/latest/">
              <FontAwesomeIcon icon={faBook} className="nav-icon"/> Stories
            </Nav.Link>
          </Nav>
          {
            loggedIn ?
              <Link className="d-flex" to={`/accounts/profile/${profileInfo?.slug}`}>
                <img className="nav-profile-image" src={profileInfo?.thumb_image}>
                  
                </img>
              </Link>
            :
              <Link className="d-flex" to="/accounts/login/">
                <img className="nav-profile-image" src={defaultImage?.thumb_image}>

                </img>
              </Link>
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}