import React from "react";
import axiosInstance from "../../x05Utils";
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";

export default function BlogMarketItemArticleDetail() {
    const { slug } = useParams();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [article, setArticle] = React.useState()

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `blog/market-landing-article/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticle(res.data);
                }
            })
        }
        getData();
    }, [slug])

    // Should be its own component
    const renderTextWithLinksAndBreaks = (text) => {
        // Check if text was provided
        if (!text) return null;
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // const parts = text.split(urlRegex);
    
        const paragraphs = text.split('\\n');

        console.log(paragraphs);
        // loop through the paragraphs and remove any remaining \ characters
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i] = paragraphs[i].replace(/\\/g, '');
            // replace tabs with spaces here if needed
        }
        
        
    
        return paragraphs.map((paragraph, index) => (
            // we still need to remove the random \ from the text after replacing the \n characters
            // also if the text contains a link the \n characters are not replaced with <br> tags
            <React.Fragment key={index}>
                {index !== 0 && <br />} {/* Add <br> except for the first line */}
                {paragraph.split(urlRegex).map((part, partIndex) => {
                    if (part.match(urlRegex)) {
                        return (
                            <a key={partIndex} href={part} target="_blank" rel="noopener noreferrer">
                                {part}
                            </a>
                        );
                    }
                    return <span key={partIndex}>{part}</span>;
                })}
            </React.Fragment>
        ));
    };

    // Not done 8/20/23 4:41am
    return (
        <>
            <Helmet>
                <title>{`${article?.title}`}</title>
                <meta name="description" content={article?.text.slice(0, 160)}/>
            </Helmet>
            <div id="blog-market-landing-article-detail-container">
                <h1 id="blog-market-landing-article-detail-title"><i>-</i> {article?.title} <i>-</i></h1>
                <hr></hr>
                <div id="blog-market-landing-article-detail-sub-data">
                    <p id="blog-market-landing-article-detail-date">{article?.date_created.slice(0, 10)}</p>
                    <p id="blog-market-landing-article-detail-user">By <i>-</i> {article?.user_username}</p>
                </div>
                <hr></hr>
                <img src={article?.image}></img>
                <div>
                    <div id="blog-market-landing-article-detail-sub-data">
                        <a id="blog-market-landing-article-detail-landing-url" href={article?.market_landing_url} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faRocket} id="blog-market-landing-article-detail-rocket"></FontAwesomeIcon> {article?.market_landing_url.slice(0, 37)}</a>
                        <p id="blog-market-landing-article-detail-landing-company">{article?.market_landing_company}</p>
                    </div>
                    <pre id="blog-market-landing-article-detail-text">{renderTextWithLinksAndBreaks(article?.text)}</pre>
                </div>
            </div>
        </>
    )
}
