import React from "react";
import RollingButton from "../../components/RollingButton";
import axiosInstance from "../../x05Utils";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function FfxivTitle() {
    const [title, setTitle] = React.useState({});
    const [gamePatch, setGamePatch] = React.useState({});
    // const [titleId, setTitleId] = React.useState(1);
    // const [titleId, setTitleId] = React.useState(1);
    const { id } = useParams(); // returning undefined

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`ffxiv/title?id=${id}`).then(res => {
                setTitle(res.data);
                setGamePatch(res.data['GamePatch']);
                console.log(res.data);
            });
        };
        getData();
    }, [id]);

    const date = new Date(gamePatch['ReleaseDate']);
    const year = date.getFullYear();    
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = "0" + date.getMinutes();
    const seconds = "0" + date.getSeconds();

    const formattedTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute.substr(-2) + ':' + seconds.substr(-2);

    return (
        <div id="ffxiv-title-container">
            <Helmet>
                <title>{`X-05 | ${title['Name']}`}</title>
                <meta name="description" content={`Unlock the prestigious FFXIV Title on X-05. Learn the requirements, benefits, and showcase your achievements. Stand out in the realm of Eorzea with the coveted title ${title['Name']} and leave your mark in the world of FFXIV!`} />
            </Helmet>
            <h1 id="ffxiv-title-title"><i>-</i> Title Details <i>-</i></h1>
            <hr className="ffxiv-title-hr"></hr>
            <div id="ffxiv-title-content">
                <h2>{title['Name']}</h2>
                <legend>Expansion - {gamePatch['Name']}</legend>
                <legend>Time - {formattedTime}</legend>
            </div>
        </div>
    )
}