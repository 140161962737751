import React from "react";
import RollingButton from "../../components/RollingButton";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faShield } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";

// Written by co-pilot needs testing

export default function FfxivTitles() {

    const[titles, setTitles] = React.useState([]);
    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);


    React.useEffect(() => {
        const getData = async () => {
            // api needs page num params
            await axiosInstance.get('ffxiv/titles').then(res => {
                setTitles(res.data["Results"]);
                setTotalPages(res.data["Pagination"]["PageTotal"]);
                console.log(res.data["Results"]);
                console.log(res.data["Pagination"]["PageTotal"]);
                console.log(typeof res.data["Pagination"]["PageTotal"])
            });
        };
        getData();
    }, []);

    // need a function to make api call to the next page.   function is written by co-pilot
    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(`ffxiv/titles?page=${currentPage + 1}`).then(res => {
                setTitles(res.data["Results"]);
                setCurrentPage(currentPage + 1);
                window.scrollTo(0, 0)
            });
        } else {
            console.log("No more pages");
        }
    };

    // need a function to make api call to the previous page.   function is written by co-pilot
    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(`ffxiv/titles?page=${currentPage - 1}`).then(res => {
                setTitles(res.data["Results"]);
                setCurrentPage(currentPage - 1);
                window.scrollTo(0, 0)
            });
        } else {
            console.log("No more pages");
        }
    };
    return (
        <div id="ffxiv-titles-container">
            <Helmet>
                <title>{`X-05 | FFXIV Titles`}</title>
                <meta name="description" content="Discover a comprehensive FFXIV Title List on X-05. Explore prestigious titles, their requirements, and unlock new achievements. Stand out in the realm of Eorzea with unique titles and leave your mark in the world of FFXIV!" />
            </Helmet>
            <h1 id="ffxiv-titles-title"><i>-</i> FFXIV Titles <i>-</i></h1>
            <hr className="ffxiv-titles-hr"></hr>
            <ul>
                {titles.map((title, index) => {
                    return (
                        <li key={index}>
                            <Link to={`/ffxiv/title/${title["ID"]}/`}><FontAwesomeIcon icon={faShield} className="ffxiv-shield"></FontAwesomeIcon> {title["Name"]} <FontAwesomeIcon icon={faShield} className="ffxiv-shield"></FontAwesomeIcon></Link>
                        </li>
                    )
                })}
            </ul>
            <hr className="ffxiv-titles-hr"></hr>
            <div id="ffxiv-titles-pagination">
                {
                    currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                }

                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                }
            </div>
        </div>
    )
}