import React from "react";
import Stars from "../../components/Stars";
import GradientButton from "../../components/GradientButton";
import { Link } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";

import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer";


export default function Home() {

    const [loggedIn] = useLogin();

    return (
        <>
            <div id="nc-main" className="nc-main bg-cover bg-cc">
                <Helmet>
                    <title>{`X-05`}</title>
                    <meta name="description" content="Discover a dynamic fusion of gaming, insightful blog articles, and lively chat on our platform. Immerse yourself in the latest gaming trends, connect with fellow gamers, and explore engaging content. Level up your gaming experience with our all-in-one blog, gaming, and chat platform." />
                </Helmet>
                <div className="full-wh">
                    <Stars></Stars>
                    <div id="landing-div">
                        <h1 id="landing-title"><i id="title-left-i">-</i> <span>X-05</span> <i id="title-right-i">-</i></h1>
                        <p id="landing-quote">The only thing necessary for the triumph of evil is for good men to do nothing <span>.</span></p>
                        <hr></hr>
                        { loggedIn ?
                            <Link to="/accounts/logout/">
                                <GradientButton message="L O G O U T"></GradientButton>
                            </Link>
                            :
                            <Link to="/accounts/login/">
                                <GradientButton message="L O G I N"></GradientButton>
                            </Link>
                        }
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}