import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";

export default function YoutubeSChannelsLatest() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [latestChannels, setLatestChannels] = React.useState();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`youtube/s/channels/latest100/`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false); // Force refresh page
                } else {
                    setLatestChannels(res.data);
                    console.log(res.data);
                }
            });
        }
        getData();
    }, [])

    // Use the same format as profiles list for now
    return(
        <div id="youtube-s-channels-latest-container">
            <Helmet>
                <title>{`X-05 | YouTube Supported Channels Latest`}</title>
                <meta name="description" content="Discover the latest YouTube channels supported by X-05. Get entertained, educated, and inspired by top creators in various niches. Start exploring now!" />
            </Helmet>
            <h1 id="youtube-s-channels-latest-title"><i>-</i> X-05 Latest Supported YouTube Channels <i>-</i></h1>
            <hr></hr>
            <div id="youtube-s-channels-latest-channels">
                {
                    latestChannels?.map((channel) => (
                        <Link to={`/youtube/s/channel/${channel.slug}/`} className="youtube-s-channels-latest-card">
                            <img src={channel.image}></img>
                            <legend className="youtube-s-channels-latest-name">{channel.name.slice(0, 7)}</legend>
                            <legend className="youtube-s-channels-latest-category">{channel.category_name.slice(0, 7)}</legend>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}