import React, {useState} from "react";
import axiosInstance, {getCookie} from "../../x05Utils";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

import { Helmet } from "react-helmet-async";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import CSRFToken from "../../components/CSRFToken";

export default function TicketCreate() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();

    const[title, setTitle] = useState("");
    const[category, setCategory] = useState("");
    const[categories, setCategories] = useState([""]);
    const[description, setDescription] = useState("");
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(null);
    const[success, setSuccess] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    let csrftoken = getCookie('csrftoken');

    React.useEffect(() => {
        const getData = async () => {
            const arr = [];
            await axiosInstance.get(
                `tickets/categories/`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } 
                // always display categories even if 401
                let result = res.data;
                result.map((cate) => {
                    arr.push({value: cate.name, label: cate.name})
                })
                setCategories(arr);
        })
        }
        getData();
    }, [setLoggedIn]);

    const handleChange = value => {
        const cate = value[Object.keys(value)[0]];
        setCategory(cate);
    }

    async function createTicket(e) {
        e.preventDefault();

        await axiosInstance(
            'dead/'
        ).then((res) => {
            if (res.status === 401) {
                console.log('Unauthorized')
                setLoggedIn(false);
                setAuthUser(null);
                localStorage.clear();
                window.location.reload(false);
            }
        })

        let form_data = new FormData();

        if (category) {
            form_data.append('category', category);
        }

        await axios.post(
            '/api/tickets/create/', form_data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('access'),
                    'X-CSRFToken': csrftoken,
                }
            }).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    navigate(
                        location?.state?.previousUrl
                            ? location.state.previousUrl
                            : "/tickets/"
                    );
                }
            })
    }

    return (
        <div id="ticket-create-container">
            <Helmet>
                <title>{`X-05 | Ticket Create`}</title>
                <meta name="description" content="Create a new ticket to report a bug or request a feature. Our team will review your ticket and get back to you as soon as possible." />
            </Helmet>
            <h1><i>-</i> Ticket Create <i>-</i></h1>
            <hr></hr>
            {
                loggedIn ? <form onSubmit={createTicket}>
                    <CSRFToken />
                </form>
                : <p>Please <Link to="/accounts/login/">login</Link> to create a ticket.</p>
            }
        </div>
    )

    
}