import React from "react";
import axiosInstance, { getCookie } from "../../x05Utils";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import axios from "axios";
import CSRFToken from "../../components/CSRFToken";
import { Helmet } from "react-helmet-async";

export default function ProfileUpdate() {
    const[profileData, setProfileData] = React.useState();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [newBanner, setNewBanner] = React.useState();
    const [newThumb, setNewThumb] = React.useState();
    const [newBio, setNewBio] = React.useState();
    const [newInstagram, setNewInstagram] = React.useState();
    const [newYoutube, setNewYoutube] = React.useState();
    const [newTwitter, setNewTwitter] = React.useState();
    const [newPintrest, setNewPintrest] = React.useState();
    const [newPersonal, setNewPersonal] =React.useState();

    const location = useLocation();
    const navigate = useNavigate();

    const { slug } = useParams();

    let csrftoken = getCookie('csrftoken')

    React.useEffect(() => {
        const getProfileData = async () => {
            await axiosInstance.get(
                `accounts/profile/${slug}/update/`
            ).then((res) => {
                if (res === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setProfileData(res.data);
                    console.log(res.data);
                }
            })
        }
        getProfileData();
    }, [setAuthUser, setLoggedIn, slug])

    // update profile function
    async function profileUpdate(e) {
        e.preventDefault();

        await axiosInstance(
            'dead/'
        ).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false)
                setAuthUser(null)
                console.log("401 was hit attempting token refresh")
                console.log(res.status)
                window.location.reload(false);
            }
        })

        let form_data = new FormData();
        if (newBanner) {
            form_data.append("banner_image", newBanner)
        }
        if (newThumb) {
            form_data.append("thumb_image", newThumb)

        }
        if (newBio) {
            form_data.append("bio", newBio)
        }
        if (newInstagram) {
            form_data.append("instagram_url", newInstagram)
        }
        if (newYoutube) {
            form_data.append("youtube_url", newYoutube)
        }
        if (newTwitter) {
            form_data.append("twitter_url", newTwitter)
        }
        if (newPintrest) {
            form_data.append("pintrest_url", newPintrest)
        }
        if (newPersonal) {
            form_data.append("personal_url", newPersonal)
        }
        await axios.patch(
            `/api/accounts/profile/${slug}/update/`, form_data, {
                headers: {
                  'Content-Type': 'multipart/form-data', 
                  Authorization: 'Bearer ' + localStorage.getItem('access'), 
                  'X-CSRFToken': csrftoken
                }
              }).then((res) => {
                if (res === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    navigate(
                        location?.state?.previousUrl
                        ? location.state.previousUrl
                        : `/accounts/profile/${slug}/`
                    )
                }
            })
    }

    return(
        <div id="account-profile-update-container">
            <Helmet>
                <title>{`X-05 | ${profileData?.user_username}`} Profile Update</title>
                <meta name="description" content="Optimize your profile on X-05 for maximum impact. Update your bio, showcase your skills, and highlight your achievements. Enhance your online presence and attract new opportunities. Start optimizing your profile today!" />
            </Helmet>
            <h1 id="account-profile-update-title"><i>-</i> X-05 Profile Update <i>-</i></h1>
            <hr></hr>
            { authUser === profileData?.user_pk ? 
                    <form id="account-profile-update-form" onSubmit={profileUpdate}>
                        <CSRFToken></CSRFToken>
                        <h2>{profileData?.user_username}</h2>
                        <div id="account-profile-update-images">
                            <div id="account-profile-update-banner">
                                <label for="banner">Banner Image</label>
                                <input name="banner" type="file" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                                    setNewBanner(e.target.files[0])
                                }}></input>
                            </div>
                            <div id="account-profile-update-thumb">
                                <label for="thumb">Thumb Image</label>
                                <input name="thumb" type="file" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                                    setNewThumb(e.target.files[0])
                                }}></input>
                            </div>
                        </div>
                        <label for="bio">Biography</label>
                        <textarea id="account-profile-update-bio" rows="20" cols="50" name="bio" defaultValue={profileData?.bio} onChange={(e) => {
                            setNewBio(e.target.value)
                        }}></textarea>
                        <div id="account-profile-update-inputs">
                            <div>
                                <label for="instagram">Instagram</label>
                                <input name="instagram" defaultValue={profileData?.instagram_url} onChange={(e) => {
                                    setNewInstagram(e.target.value)
                                }}></input>
                            </div>
                            <div>
                                <label for="youtube">Youtube</label>
                                <input name="youtube" defaultValue={profileData?.youtube_url} onChange={(e) => {
                                    setNewYoutube(e.target.value)
                                }}></input>
                            </div>
                            <div>
                                <label for="twitter">Twitter</label>
                                <input name="twitter" defaultValue={profileData?.twitter_url} onChange={(e) => {
                                    setNewTwitter(e.target.value)
                                }}></input>
                            </div>
                            <div>
                                <label for="pintrest">Pintrest</label>
                                <input name="pintrest" defaultValue={profileData?.pintrest_url} onChange={(e) => {
                                    setNewPintrest(e.target.value)
                                }}></input>
                            </div>
                            <div>
                                <label for="personal">Website</label>
                                <input name="personal" defaultValue={profileData?.personal_url} onChange={(e => {
                                    setNewPersonal(e.target.value)
                                })}></input>
                            </div>
                        </div>
                        <RollingButton type="submit" message="Update"></RollingButton>
                    </form> 
                : 
                    <h2>You're not the owner of this Profile</h2>
            }
        </div>
    )
}