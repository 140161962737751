import React from "react";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";


export default function RecipesList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [targetSearch, setTargetSearch] = React.useState("");
    const [searchType, setSearchType] = React.useState("list");

    const searchData = async (e) => {
        const cleanedSearch = targetSearch.replace(" ", "-");
        if (e.key === "Enter") {
            // navigate to correct page
            console.log(cleanedSearch);
            if (searchType === "list") {
                window.location.href = `/recipes/list/${cleanedSearch}/`;
            } else {
                window.location.href = `/recipes/random/${cleanedSearch}/`;
            }
        }
    };
    const toggleSearchType = async (e) => {
        if (searchType === "list") {
            setSearchType("random");
        } else {
            setSearchType("list");
        }
    }

    // we need a toggle switch for random or list search type


    return (
        <div id="recipes-search-container">
            <Helmet>
                <title>{`X-05 | Recipes Search`}</title>
                <meta name="description" content="Discover a wide variety of delicious recipes. Search by ingredients, cuisine, and dietary preferences to find the perfect dish for every occasion." />
            </Helmet>
            <div id="recipes-search-toggle-div">
                <label>Normal - Random</label>
                <input type="checkbox" id="recipes-search-toggle" onChange={toggleSearchType}></input>
            </div>
            <div id="recipes-search-bar-div">
                <h1>Recipes Search</h1>
                <input type="text" name="search" placeholder="Search" onChange={e => setTargetSearch(e.target.value)} onKeyUp={searchData}></input>
            </div>
        </div>
    )
}