import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";
import RollingButton from "../../components/RollingButton";

// List all market items no matter the company
export default function MarketItemList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [items, setItems] = React.useState([]);

    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);



    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`market/items?page=${currentPage}`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false)
                } else {
                    setItems(res.data.results);
                    console.log(res.data);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 17 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 17 should be obtained through a dynamic variable
                }
            });
        }
        getData();
    }, []);

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(`market/items?page=${currentPage + 1}`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false)
                } else {
                    setItems(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0);
                }
            });
        } else {
            console.log('No more pages')
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(`market/items?page=${currentPage - 1}`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false)
                } else {
                    setItems(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0);
                }
            });
        } else {
            console.log('No more pages')
        }
    }

    // Special Function for making api call to analytics and sending user to url of item
    async function sendToAnalytics(e, lproduct) {
        e.preventDefault();

        // Need to send over the foriegn object of makret item
        // console.log(code);

        const newWindow = window.open(lproduct.url, '_blank');
        if (newWindow) newWindow.opener = null;

        await axiosInstance.post(
            'analytics/market-item-click/create/', {
                market_item: lproduct.code,
            }
        ).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false);
                setAuthUser(null);
                localStorage.clear();
                window.location.reload(false);
            } else {
                console.log(res.data);
            }
        }
        )
    }

    // Need function to clean item names for displaying
    const truncatedText = (text) => {
        if (text.length > 55) {
            return text.substring(0, 52) + '...';
        } else {
            return text;
        }
    }

    return (
        <div id="market-item-list-container">
            <Helmet>
                <title>{`X-05 | Market Items`}</title>
                <meta name="description" content="Explore our comprehensive list of affiliate items on X-05. Discover exclusive partnerships, offers, and products from leading brands in their industry. Enhance your life with our curated selection of affiliate items and unlock exciting opportunities." />
            </Helmet>
            <h1 id="market-item-list-title"><i>-</i> Market Items <i>-</i></h1>
            <hr></hr>
            <div id="market-item-list-products">
                {
                    items?.map(item => (
                        <a 
                            onClick={(e) => sendToAnalytics(e, item)}
                            className="market-item-list-card"
                        >
                            {
                                item?.image_url ?
                                    <img src={item?.image_url}></img>
                                    :
                                    <img src={item?.backup_image}></img>
                            }
                            <div className="market-item-list-product-div">
                                <p className="market-item-list-product-company">{item?.company_name}</p>
                                <hr></hr>
                            </div>
                            <h2 className="market-item-list-product-name">{truncatedText(item?.name)}</h2>
                        </a>
                    ))
                }
            </div>
            <hr></hr>
            <div id="market-item-list-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message="Previous Page" /> : null
                }
                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message="Next Page" /> : null
                }
            </div>
        </div>
    )
}
