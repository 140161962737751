import React from "react";
import axiosInstance, { getCookie } from "../../x05Utils";
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import axios from "axios";
import CSRFToken from "../../components/CSRFToken";
import { Helmet } from "react-helmet-async";

export default function PollsQuestionDetail() {
    const { slug } = useParams();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [question, setQuestion] = React.useState(null);
    const [choices, setChoices] = React.useState(null);
    const [comments, setComments] = React.useState(null);
    const [pastVote, setPastVote] = React.useState(null);
    const [voteError, setVoteError] = React.useState(null);
    const [voteSuccess, setVoteSuccess] = React.useState(null);
    let csrftoken = getCookie('csrftoken')

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`polls/question/${slug}/`).then(res => {
                if (res.request.status === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear()
                    window.location.reload(false);
                } else {
                    setQuestion(res.data.question);
                    setChoices(res.data.choices);
                    setComments(res.data.comments);
                    console.log(res.data);
                }
            });

            // get vote data if it exists
            await axios.get(`/api/polls/vote/${slug}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access')}`
                }
            }).then(res => {
                if (res.request.status === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear()
                    window.location.reload(false);
                } else if (res.request.status === 404) {
                    console.log("Vote doesn't exist")
                } else {
                    setPastVote(res.data.choice);
                    console.log(res.data);
                }
            });
        };
        getData();
    }, []);

    const handleVote = async (e) => {
        e.preventDefault();
        const choice = parseInt(e.target.value);
        await axiosInstance(
            'dead/'
        ).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false)
                setAuthUser(null)
                console.log("401 was hit attempting token refresh")
                localStorage.clear()
                window.location.reload(false);
            }
        })
        const data = {
            "choice": choice,
        }
        if (pastVote === null) {
            await axios.post(`/api/polls/vote/createupdate/${slug}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access')}`, 
                    'X-CSRFToken': csrftoken
                }
            }).then(res => {
                if (res.request.status === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear()
                } else {
                    setPastVote(choice);
                    setVoteSuccess(res.data.message);
                    console.log(res.data);
                }
                window.location.reload(false);
            }).catch(err => {
                setVoteError(err.response.data.message);
            });
        } else {
            // console.log(pastVote)
            // console.log(choice)
            if ( pastVote !== choice ) {
                await axios.patch(`/api/polls/vote/createupdate/${slug}/`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('access')}`, 
                        'X-CSRFToken': csrftoken
                    }
                }).then(res => {
                    if (res.request.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        localStorage.clear()
                        window.location.reload(false);
                    } else {
                        setPastVote(choice);
                        setVoteSuccess(res.data.message);
                        setVoteError(null);
                        console.log(res.data);
                    }
                }).catch(err => {
                    setVoteError(err.response.data.message);
                    setVoteSuccess(null);
                });
            } else {
                // console.log("Vote already exists for this choice")
                setVoteError("Vote already exists for this choice")
                setVoteSuccess(null);
            }
        }
    }

    return (
        <div id="polls-question-detail-container">
            <Helmet>
                <title>{`X-05 | Polls | ${question?.question_topic}`}</title>
                <meta name="description" content={`Explore the latest polls on ${question?.question_topic}. Gain valuable insights, tips, and expert advice in this comprehensive collection. Stay updated with the latest trends and developments. Start reading now!`} />
            </Helmet>
            <h1 id="polls-question-detail-title"><i>-</i> Poll Question Detail <i>-</i></h1>
            <hr></hr>
            {
                question?.is_active === true ? 
                    <div id="polls-question-detail-question">
                        <h2 id="polls-question-category">Category - {question.linked_category}</h2>
                        <h3 id="polls-question-topic">{question.question_topic}</h3>
                        <h4 id="polls-question-text">{question.question_text}</h4>
                        <h5 id="polls-question-publish-date">Date Published - {question.publish_date.slice(0, 10)}</h5>
                        {
                            voteSuccess ? 
                                <div id="polls-question-detail-vote-success">
                                    <h2>{voteSuccess}</h2>
                                </div>
                            :
                            null
                        }
                        {
                            voteError ? 
                                <div id="polls-question-detail-vote-error">
                                    <h2>{voteError}</h2>
                                </div>
                            :
                            null
                        }
                        {
                            choices ? 
                                <div id="polls-question-detail-choices">
                                    {
                                        choices.map(choice => 
                                            <div key={choice.pk} className="polls-question-detail-choice">
                                                <h2>{choice.choice_text}</h2>
                                                <h3>Votes <i>-</i> {choice.get_vote_amount}</h3>
                                                <RollingButton click={handleVote} value={choice.pk} message={"Select"} />
                                            </div>
                                            )
                                    }
                                </div>
                            :
                            null
                        }      
                    </div>
                :
                null
            }
            <hr></hr>
            <div id="polls-question-detail-votes" >
                {
                    question?.get_vote_amount > 0 ?
                        <h2>Total Votes: {question.get_vote_amount}</h2>
                    :
                        <h2>No Votes</h2>
                }
            </div>
            <hr></hr>
            {
                question?.get_comment_amount > 0 ?
                    <div id="polls-question-detail-comments">
                        {
                            comments.map(comment =>
                                <div key={comment.pk} className="polls-question-detail-comment">
                                    <h2>{comment.text}</h2>
                                    <h3>{comment.date_posted.slice(0, 10)}</h3>
                                    <h4>{comment.user_username}</h4>
                                </div>
                                )
                        }
                    </div>
                : 
                    <div id="polls-question-detail-comments">
                        <h2>No comments yet</h2>
                    </div>
            }
        </div>
    )
}