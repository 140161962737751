import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import axios from "axios";
import { Helmet } from "react-helmet-async";

export default function MarketHome() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    React.useEffect(() => {
        const getData = async () => {
            // This api endpoint isnt setup yet it will throw a 404
            await axiosInstance.get(
                `market/`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                }
            })
        }
        getData();
    }, [])

    return (
        <>
            <Helmet>
                <title>{`X-05 | Market Home`}</title>
                <meta name="description" content="This page contains information about affiliate marketing programs and products. Learn more about the products and services X-05 promotes."></meta>
            </Helmet>
            <div id="market-home-container">
                <h1 id="market-home-title"><i>-</i> Market Home <i>-</i></h1>
                <hr></hr>
                <div id="market-home-links">
                    <Link id="market-home-market-item-articles" to="/blog/market-item-articles/latest/">
                        <h2 id="market-home-market-item-articles-title">Market Item Articles</h2>
                    </Link>
                    <Link id="market-home-collections" to="/market/collections/">
                        <h2 id="market-home-collections-title">Collections</h2>
                    </Link>
                    <Link id="market-home-companies" to="/market/companies/">
                        <h2 id="market-home-companies-title">Companies</h2>
                    </Link>
                    <Link id="market-home-items" to="/market/items/">
                        <h2 id="market-home-items-title">Items</h2>
                    </Link>
                    <Link id="market-home-items-latest" to="/market/items/latest/">
                        <h2 id="market-home-items-latest-title">Latest Items</h2>
                    </Link>
                    <Link id="market-home-landings" to="/market/landings/">
                        <h2 id="market-home-landings-title">Landings</h2>
                    </Link>
                    <Link id="market-home-landings-latest" to="/market/landings/latest/">
                        <h2 id="market-home-landings-latest-title">Latest Landings</h2>
                    </Link>
                </div>
            </div>
        </>
    )
}