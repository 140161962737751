import React, { useState } from "react";
import axios from "axios";
import { axiosConfig, mainUrl } from "../../x05Utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight, faHandPointLeft } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from "react-helmet-async";


export default function FfxivUpdates() {
    const[updates, setUpdates] = useState([]);

    React.useEffect(() => {
        const getData = async () => {
            await axios.get(mainUrl.concat('ffxiv/updates/'), axiosConfig).then(res => {
                setUpdates(res.data);
            });
        };
        getData();
    }, []);

    return(
        <div id="ffxiv-updates-container">
            <Helmet>
                <title>{`X-05 | FFXIV Updates`}</title>
                <meta name="description" content="Stay up to date with the latest FFXIV Updates on X-05. Get the scoop on new content, patches, events, and more. Dive into the ever-evolving world of FFXIV and never miss a beat with our comprehensive updates!" />
            </Helmet>
            <h1 id="ffxiv-updates-title"><i>-</i> FFXIV Updates <i>-</i></h1>
            <table id="ffxiv-updates-table">
                <tr id="ffxiv-updates-table-titles">
                    <th>Type</th>
                    <th>Date</th>
                    <th>URL</th>
                </tr>
                {
                    updates.map(update => 
                        
                        <tr className="ffxiv-updates-data">
                            <td>{update.title}</td>
                            <td>{update.time.slice(0, 10)}</td>
                            <td>
                                <a href={update.url}><FontAwesomeIcon icon={faHandPointRight} className="ffxiv-point" target="_blank" rel="noopener noreferrer"/> {update.url} <FontAwesomeIcon icon={faHandPointLeft} className="ffxiv-point"/></a>
                            </td>
                        </tr>
                    )
                }
            </table>
        </div>
    )
}