import X05Alert from "../components/XAlert";
import { useLocation } from "react-router-dom";
import React from "react";
import axiosInstance from "../x05Utils";
import { useLogin } from "../contexts/LoginContext";

import { useUser } from "../contexts/UserContext";


// This component needs some kind of delay so its use of axiosInstance doesn't overlap with the other
// axiosInstance calls and cause a 401 error by using a blacklisted token


export default function EmailListSubscriberAlert() {

    const location = useLocation();
    const [showAlert, setShowAlert] = React.useState(true);
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    // isLoading isnt really used in this component but here for future use
    const [isLoading, setIsLoading] = React.useState(true);

    // Brain storming 7/13/23
    // Grab the the ip from visitor and check if it's in the database for email list
    // If it's not in the database, show the alert
    // If it is in the database, don't show the alert
    // Needs a custom view and serializer to stop the emails from being shown in the api response
    // This needs to be done in a way that doesn't leak ips to users

    React.useEffect(() => {
        const getData = async () => {
            setTimeout(async () => {
                try {
                    const res = await axiosInstance.get("email-list/subscriber/check/");
                    if (res.status === 200) {
                        const isAnySubscribed = res.data.some(item => item.is_subscribed === true);
                        setShowAlert(!isAnySubscribed);
                    } else if (res.status === 404) {
                        setShowAlert(true);
                    } else if (res.status === 401) {
                        setShowAlert(true);
                        console.log("Unauthorized")
                        setLoggedIn(false);
                        setAuthUser(null);
                        localStorage.clear();
                        window.location.reload(false);
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    // console.log("email list subscriber alert finally block")
                    setIsLoading(false);
                }
            }, 500);
        }
        getData();
    }, []);

    const excludePaths = [
        "/",
        "/email-list/subscriber/create/",
    ]

    if (!excludePaths.includes(location.pathname)) {
    
        return (
            <>
                <X05Alert link="/email-list/subscriber/create/" linkText="Join Our Email List For The Latest X-05 Updates" id="email-list-x-alert" linkId="email-list-x-link">
                </X05Alert>
            </>
        )
    } else {
        return null
    }
}