import React from "react";
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <footer className="footer">
            <Link to='/about/' className="footer-links">About</Link>
            <span className="footer-pipes">|</span>
            <Link to='/' className="footer-links">Contact</Link>
            <span className="footer-pipes">|</span>
            <Link to='/' className="footer-links">Terms</Link>
            <span className="footer-pipes">|</span>
            <Link to='/privacy/' className="footer-links">Privacy</Link>
            <span className="footer-pipes">|</span>
            <a href='https://rapidapi.com/user/BFG9000' target="_blank" rel="noopener noreferrer" className="footer-links">API</a>
            <span className="footer-pipes">|</span>
            <a href='https://www.paypal.com/donate/?hosted_button_id=JP25UT6EL2XZQ' target="_blank" rel="noopener noreferrer" className="footer-links">Donate</a>
        </footer>
    )
}