// Stories Category List Page
import React from 'react';
import axiosInstance, { mainUrl } from '../../x05Utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLogin } from '../../contexts/LoginContext';
import { useUser } from '../../contexts/UserContext';
import RollingButton from '../../components/RollingButton';
import { Helmet } from 'react-helmet-async';
import { faDroplet } from '@fortawesome/free-solid-svg-icons';

export default function StoryCategories() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [categories, setCategories] = React.useState();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get('stories/categories/').then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized');
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data);
                    setCategories(res.data);
                }
            });
        }
        getData();
    }, [])

    return(
        <>
            <Helmet>
                <title>{`X-05 | Stories Categories`}</title>
                <meta name="description" content="Discover captivating stories from various categories on our platform. Explore a wide range of genres, including mystery, romance, adventure, and more. Immerse yourself in a world of imagination and creativity. Start reading now!" />
            </Helmet>
            <div id="stories-categories-container">
                <h1 id="stories-categories-title"><i>-</i> X-05 Stories Categories <i>-</i></h1>
                <hr></hr>
                <ul>
                    {
                        categories?.map(cate =>
                            <li>
                                <Link className="stories-category-link" to={`/stories/category/${cate.slug}/`}><FontAwesomeIcon icon={faDroplet} className="stories-category-droplets"></FontAwesomeIcon> {cate.name} <FontAwesomeIcon icon={faDroplet} className="stories-category-droplets"></FontAwesomeIcon></Link>
                                <hr></hr>
                            </li>
                        )
                    }
                </ul>
            </div>
        </>
    )
}