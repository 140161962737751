import React from "react";
import axiosInstance, { x05Delay } from "../../x05Utils";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import axios from "axios";
import { Helmet } from "react-helmet-async";

export default function BlogHome() {
    const [randomPost, setRandomPost] = React.useState();
    const [randomCategories, setRandomCategories] = React.useState();
    const [latestPosts, setLatestPosts] = React.useState();
    const [popularPosts, setPopularPosts] = React.useState();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    React.useEffect(() => {
        const getData = async () => {
            // MAKING MULTIPLE API REQUESTS IN 1 useEffect 
            // https://anothertechs.com/programming/react/react-api-call/
            // only 1 axios instance call per Promise.all call to multiple apis
            
            await Promise.all([
                axiosInstance.get(
                    'blog/post/random/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setRandomPost(res.data)
                    }
                }),
                axios.get(
                    '/api/blog/categories/random/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setRandomCategories(res.data)
                    }
                }), 
                axios.get(
                    '/api/blog/posts/latest/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setLatestPosts(res.data)
                    }
                }), 
                axios.get(
                    '/api/blog/posts/popular/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setPopularPosts(res.data)
                    }
                })
            ])
        }
        getData();

    }, [])


    return (
        <div id="blog-home-container">
            <Helmet>
                <title>{`X-05 | Blog Home`}</title>
                <meta name="description" content="Discover our comprehensive blog with a wide range of topics. Gain valuable insights, tips, and expert advice. Stay informed and explore the latest trends and developments. Start reading now!" />
            </Helmet>
            <h1 id="blog-home-title" ><i>-</i> Blog Home <i>-</i></h1>
            <hr></hr>
            <ul id="blog-home-nav">
                <li>
                    <Link to="/blog/categories/">
                        <RollingButton message="Categories"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/blog/posts/create/">
                        <RollingButton message="Create"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/blog/posts/latest/">
                        <RollingButton message="Latest Posts"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/blog/articles/latest/">
                        <RollingButton message="Latest Articles"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/blog/articles/">
                        <RollingButton message="Articles"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/blog/posts/">
                        <RollingButton message="Posts"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/blog/market-item-articles/">
                        <RollingButton message="M-I-Articles"></RollingButton>
                    </Link>
                </li>
                <li>
                    <Link to="/blog/market-item-articles/latest/">
                        <RollingButton message="M-I-Latest"></RollingButton>
                    </Link>
                </li>         
            </ul>
            <div id="blog-home-cards">
                <div id="blog-home-latest">
                    <h2>Last 5 posts</h2>
                    <hr></hr>
                    <ul>
                        {
                            latestPosts?.map(lpost => 
                                    <li>
                                        <Link to={`/blog/post/${lpost.slug}/`}>{lpost.title.slice(0, 50)}</Link>
                                    </li>
                                )
                        }
                    </ul>
                </div>
                <div id="blog-home-categories">
                    <h2>Categories</h2>
                    <hr></hr>
                    <ul>
                        {
                            randomCategories?.map(cate => 
                                    <li>
                                        <Link to={`/blog/category/${cate.slug}/`} >{cate.name.slice(0, 50)}</Link>
                                    </li>
                                )
                        }
                    </ul>
                </div>
                <div id="blog-home-popular">
                    <h2>Popular Posts</h2>
                    <hr></hr>
                    <ul>
                        {
                            popularPosts?.map(ppost => 
                                    <li>
                                        <Link to={`/blog/post/${ppost.slug}/`}>{ppost.title.slice(0, 50)}</Link>
                                    </li>
                                )
                        }
                    </ul>
                </div>
            </div>
            <Link id="blog-home-summary" to={`/blog/post/${randomPost?.slug}/`}>
                <img src={randomPost?.blog_image0}></img>
                <div>
                    <h3>{randomPost?.title}</h3>
                    <hr></hr>
                    <p>{randomPost?.text_preview}</p>
                </div>
                <p id='blog-home-summary-author'><span>By {randomPost?.user_username}</span> <i>-</i> {randomPost?.date_created.slice(0, 10)}</p>
            </Link>
        </div>
    )
}