import React, { useContext } from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import axios from "axios";
import { Helmet } from "react-helmet-async";

export default function BlogPostDetail() {

    const [postDetails, setPostDetails] = React.useState();
    const { slug } = useParams();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [toggleStatus, setToggleStatus] = React.useState(false);
    const [likeCreated, setLikeCreated] = React.useState(false);
    const [likeCount, setLikeCount] = React.useState(0); 
    
    React.useEffect(() => {
        const getData = async () => {
            // https://stackoverflow.com/questions/73092249/multiple-api-calls-with-promise-all
            await axiosInstance.get(
                `blog/post/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setPostDetails(res.data);
                    // Bug is here for like counter 3/16/23
                    setLikeCount(res.data.get_likes_amount);
                    console.log(res.data);
                }
            })
            await axios.get(
                `/api/blog/like/${slug}/`, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('access')
                    }
                }
            ).then((res) => {
                if (res.status === 404) {
                    setLikeCreated(false);
                } else if (res.status === 401){
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLikeCreated(true);
                    console.log(res.data.status)
                    setToggleStatus(res.data.status)
                }
            })
        }
        getData();
    }, [slug, setLoggedIn]);

    // Should be its own component
    const renderTextWithClickableLinks = (text) => {
        // Check if text was provided
        
        if (!text) return;

        // Only turn https links to clickable links not http
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = text.split(urlRegex);

        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                )
            }
            return <span key={index}>{part}</span>;
        })
    };

    const handleClick = async (e) => {
        e.preventDefault();
        // https://www.daggala.com/react-state-not-updating-immediately/
        const newToggleStatus = !toggleStatus;
        // https://stackoverflow.com/questions/40359800/how-to-toggle-boolean-state-of-a-react-component
        setToggleStatus(prevState => !prevState);
        console.log(newToggleStatus)
        
        if (likeCreated === false) {
            await axiosInstance.post(
                `blog/like/${slug}/create/`, {
                    status: newToggleStatus
                }
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res)
                    if (newToggleStatus === true) {
                        setLikeCount(likeCount + 1)
                    } else {
                        setLikeCount(likeCount - 1)
                    }
                }
            })
        } else {
            await axiosInstance.patch(
                `blog/like/${slug}/create/`, {
                    status: newToggleStatus
                }
            ).then((res) => {
                if(res.status === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res);
                    if (newToggleStatus === true) {
                        setLikeCount(likeCount + 1)
                    } else {
                        setLikeCount(likeCount - 1)
                    }
                }
            })
        }
    }

    return (
        <div id="blog-post-detail-container">
            <Helmet>
                <title>{`${postDetails?.title}`}</title>
                <meta name="description" content={postDetails?.text0.slice(0, 160)} />
            </Helmet>
            <h1 id="blog-post-detail-title"><i>-</i> {postDetails?.title} <i>-</i></h1>
            <hr></hr>
            <div>
                <div id="blog-post-detail-info">
                    <Link to={`/blog/category/${postDetails?.blog_category}/`}>{postDetails?.blog_category}</Link>
                    <Link>By <i>-</i> {postDetails?.user_username}</Link>
                </div>
                <hr></hr>
                <ul id="blog-post-detail-nav">
                    <li>
                        <Link to="/blog/categories/">
                            <RollingButton message="Categories"></RollingButton>
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog/posts/create/">
                            <RollingButton message="Create"></RollingButton>
                        </Link>
                    </li>
                </ul>
                {
                    loggedIn ? <div id="blog-post-detail-utils">
                                    <legend>Likes <i>-</i> {likeCount}</legend>
                                    {
                                    toggleStatus ?
                                            <RollingButton message="Unlike" click={handleClick}></RollingButton>
                                        :
                                            <RollingButton message="Like" click={handleClick}></RollingButton>
                                    }
                                    {
                                        authUser === postDetails?.user_pk ?
                                            <Link to={`/blog/post/update/${postDetails?.slug}/`}>
                                                <RollingButton message="Update"></RollingButton>
                                            </Link>
                                        :
                                            null
                                    }
                                </div> : null
                }
                <div id="blog-post-detail-article">
                    { postDetails?.head0 ? <h2>{postDetails?.head0}</h2> : null}
                    { postDetails?.url0 ? <a href={postDetails?.url0} target="_blank" rel="noopener noreferrer">{postDetails?.url0}</a> : null}
                    { postDetails?.text0 ? <p id="blog-post-detail-starting-p">{postDetails?.text0}</p> : null }
                    { postDetails?.blog_image0 ? <img src={postDetails?.blog_image0}></img> : null }
                    { postDetails?.head1 ? <h2>{postDetails?.head1}</h2> : null}
                    { postDetails?.url1 ? <a href={postDetails?.url1} target="_blank" rel="noopener noreferrer">{postDetails?.url1}</a> : null}
                    { postDetails?.text1 ? <p>{postDetails?.text1}</p> : null }
                    { postDetails?.blog_image1 ? <img src={postDetails?.blog_image1}></img> : null }
                    { postDetails?.head2 ? <h2>{postDetails?.head2}</h2> : null}
                    { postDetails?.url2 ? <a href={postDetails?.url2} target="_blank" rel="noopener noreferrer">{postDetails?.url2}</a> : null}
                    { postDetails?.text2 ? <p>{postDetails?.text2}</p> : null }
                    { postDetails?.head3 ? <h2>{postDetails?.head3}</h2> : null}
                    { postDetails?.url3 ? <a href={postDetails?.url3} target="_blank" rel="noopener noreferrer">{postDetails?.url3}</a> : null}
                    { postDetails?.text3 ? <p>{postDetails?.text3}</p> : null }
                    { postDetails?.blog_image2 ? <img src={postDetails?.blog_image2}></img> : null }
                    { postDetails?.head4 ? <h2>{postDetails?.head4}</h2> : null}
                    { postDetails?.url4 ? <a href={postDetails?.url4} target="_blank" rel="noopener noreferrer">{postDetails?.url4}</a> : null}
                    { postDetails?.text4 ? <p>{postDetails?.text4}</p> : null }
                    { postDetails?.blog_image3 ? <img src={postDetails?.blog_image3}></img> : null }
                    { postDetails?.head5 ? <h2>{postDetails?.head5}</h2> : null}
                    { postDetails?.url5 ? <a href={postDetails?.url5} target="_blank" rel="noopener noreferrer">{postDetails?.url5}</a> : null}
                    { postDetails?.text5 ? <p>{postDetails?.text5}</p> : null }
                    { postDetails?.head6 ? <h2>{postDetails?.head6}</h2> : null}
                    { postDetails?.url6 ? <a href={postDetails?.url6} target="_blank" rel="noopener noreferrer">{postDetails?.url6}</a> : null}
                    { postDetails?.text6 ? <p>{postDetails?.text6}</p> : null }
                    { postDetails?.blog_image4 ? <img src={postDetails?.blog_image4}></img> : null }
                    { postDetails?.head7 ? <h2>{postDetails?.head7}</h2> : null}
                    { postDetails?.url7 ? <a href={postDetails?.url7} target="_blank" rel="noopener noreferrer">{postDetails?.url7}</a> : null}
                    { postDetails?.text7 ? <p>{postDetails?.text7}</p> : null }
                    { postDetails?.blog_image5 ? <img src={postDetails?.blog_image5}></img> : null }
                    { postDetails?.head8 ? <h2>{postDetails?.head8}</h2> : null}
                    { postDetails?.url8 ? <a href={postDetails?.url8} target="_blank" rel="noopener noreferrer">{postDetails?.url8}</a> : null}
                    { postDetails?.text8 ? <p>{postDetails?.text8}</p> : null }
                    { postDetails?.blog_image6 ? <img src={postDetails?.blog_image6}></img> : null }
                    { postDetails?.head9 ? <h2>{postDetails?.head9}</h2> : null}
                    { postDetails?.url9 ? <a href={postDetails?.url9} target="_blank" rel="noopener noreferrer">{postDetails?.url9}</a> : null}
                    { postDetails?.text9 ? <p>{postDetails?.text9}</p> : null }
                </div>
            </div>
        </div>
    )
}