import React from "react";
import RollingButton from "../../components/RollingButton";
import axiosInstance from "../../x05Utils";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function FfxivJob() {
    const [job, setJob] = React.useState({});
    const { id } = useParams(); // returning undefined

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`ffxiv/job?id=${id}`).then(res => {
                setJob(res.data);
                console.log(res.data);
            });
        };
        getData();
    }, [id]);

    return (
        <div id="ffxiv-job-container">
            <Helmet>
                <title>{`X-05 | ${job['Name']}`}</title>
                <meta name="description" content={`Learn more about the FFXIV Job ${job['Name']} on X-05. Discover its role, weapon, and more. Level up your gaming journey with our extensive job guide!`} />
            </Helmet>
            <h1 id="ffxiv-job-title"><i>-</i> Job Details <i>-</i></h1>
            <hr className="ffxiv-job-hr"></hr>
            <div id="ffxiv-job-content">
                <img src={job['Icon']}></img>
                <div id="ffxiv-job-info">
                    <h2>{job['Abbreviation']}</h2>
                    <hr></hr>
                    <h3>{job['Name']}</h3>
                </div>
            </div>
        </div>
    )
}