import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";

import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";


export default function RecipesList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [recipes, setRecipes] = React.useState([]);

    const { search_str } = useParams();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`recipes/random?q=${search_str}`).then(res => {
                setRecipes(res.data.hits);
                console.log(res.data.count);
            });
        };
        getData();
    }, [search_str]);

    return (
        <div id="recipes-random-container">
            <Helmet>
                <title>{`X-05 | Recipes Random | ${search_str}`}</title>
                <meta name="description" content={`Explore the latest recipes on ${search_str}. Gain valuable insights, tips, and expert advice in this comprehensive collection. Stay updated with the latest trends and developments. Start reading now!`} />
            </Helmet>
            <h1 id="recipes-random-title"><i>-</i> Recipes <i>-</i></h1>
            <hr></hr>
            {
                recipes.map(recipe => (
                    <Link className="recipes-random-card" to={`/recipes/recipe/${recipe['uri']}/`}>
                        <img src={recipe.image}></img>
                        <div className="recipes-random-card-data">
                            <h2>{recipe.label}</h2>
                            <hr></hr>
                            {
                                recipe.cuisinetype.map(cuisine => (
                                    <p>{cuisine}</p>
                                ))
                            }
                        </div>
                        <div className="recipes-random-card-sub-data">
                            {
                                recipe.healthlabels.map(healthlabel => (
                                    <p>{healthlabel}</p>
                                ))
                            }
                            {
                                recipe.dietlabels.map(dietlabel => (
                                    <p>{dietlabel}</p>
                                ))
                            }
                        </div>
                    </Link>
                ))
            }


        </div>
    )
}
