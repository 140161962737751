import React from "react";
import axiosInstance, { ffxivUrl } from "../../x05Utils";
import RollingButton from "../../components/RollingButton";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faDog } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet-async";


export default function FfxivCompanions() {
    
        const[companions, setCompanions] = React.useState([]);
        const[currentPage, setCurrentPage] = React.useState(1);
        const[totalPages, setTotalPages] = React.useState(1);
    
        React.useEffect(() => {
            const getData = async () => {
                // api needs page num params
                await axiosInstance.get('ffxiv/companions').then(res => {
                    setCompanions(res.data["Results"]);
                    setTotalPages(res.data["Pagination"]["PageTotal"]);
                    console.log(res.data["Results"]);
                    console.log(res.data["Pagination"]["PageTotal"]);
                    console.log(typeof res.data["Pagination"]["PageTotal"])
                });
            };
            getData();
        }, []);
    
        // need a function to make api call to the next page.   function is written by co-pilot
        const nextPage = async () => {
            if (currentPage < totalPages) {
                await axiosInstance.get(`ffxiv/companions?page=${currentPage + 1}`).then(res => {
                    setCompanions(res.data["Results"]);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                });
            } else {
                console.log("No more pages");
            }
        };
    
        // need a function to make api call to the previous page.   function is written by co-pilot
        const prevPage = async () => {
            if (currentPage > 1) {
                await axiosInstance.get(`ffxiv/companions?page=${currentPage - 1}`).then(res => {
                    setCompanions(res.data["Results"]);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                });
            } else {
                console.log("No more pages");
            }
        };
        return (
            <div id="ffxiv-companions-container">
                <Helmet>
                    <title>{`X-05 | FFXIV Companions`}</title>
                    <meta name="description" content="Discover a diverse collection of FFXIV Companions on X-05. Find your perfect ally, unlock their unique abilities, and embark on epic adventures together. Choose your companion and enhance your gaming experience!" />
                </Helmet>
                <h1 id="ffxiv-companions-title"><i>-</i> FFXIV Companions <i>-</i></h1>
                <hr></hr>
                <ul>
                    {companions.map((companion, index) => {
                        return (
                            <li key={index}>
                                <img src={companion["Icon"]}></img>
                                <Link to={`/ffxiv/companion/${companion["ID"]}/`}>{companion["Name"]}</Link>
                            </li>
                        )
                    })}
                </ul>
                <hr></hr>
                <div id="ffxiv-companions-pagination">
                    {
                        currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                    }

                    {
                        currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                    }
                </div>
            </div>
        )
    }
