import React from "react";
import axiosInstance, { ffxivUrl } from "../../x05Utils";
import RollingButton from "../../components/RollingButton";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function FfxivActions() {
        
            const[Actions, setActions] = React.useState([]);
            const[currentPage, setCurrentPage] = React.useState(1);
            const[totalPages, setTotalPages] = React.useState(1);
        
            React.useEffect(() => {
                const getData = async () => {
                    // api needs page num params
                    await axiosInstance.get('ffxiv/actions').then(res => {
                        setActions(res.data["Results"]);
                        setTotalPages(res.data["Pagination"]["PageTotal"]);
                        console.log(res.data["Results"]);
                        console.log(res.data["Pagination"]["PageTotal"]);
                        console.log(typeof res.data["Pagination"]["PageTotal"])
                    });
                };
                getData();
            }, []);
        
            // need a function to make api call to the next page.   function is written by co-pilot
            const nextPage = async () => {
                if (currentPage < totalPages) {
                    await axiosInstance.get(`ffxiv/actions?page=${currentPage + 1}`).then(res => {
                        setActions(res.data["Results"]);
                        setCurrentPage(currentPage + 1);
                        window.scrollTo(0, 0)
                    });
                } else {
                    console.log("No more pages");
                }
            };
        
            // need a function to make api call to the previous page.   function is written by co-pilot
            const prevPage = async () => {
                if (currentPage > 1) {
                    await axiosInstance.get(`ffxiv/actions?page=${currentPage - 1}`).then(res => {
                        setActions(res.data["Results"]);
                        setCurrentPage(currentPage - 1);
                        window.scrollTo(0, 0)
                    });
                } else {
                    console.log("No more pages");
                }
            };
            return (
                <div id="ffxiv-actions-container">
                    <Helmet>
                        <title>{`X-05 | FFXIV Actions`}</title>
                        <meta name="description" content="Explore a comprehensive collection of FFXIV actions on X-05. Learn more about their effects, cooldown, and more. Level up your gaming journey with our extensive action guide!" />
                    </Helmet>
                    <h1 id="ffxiv-actions-title"><i>-</i> FFXIV Actions <i>-</i></h1>
                    <hr className="ffxiv-actions-hr"></hr>
                    <ul>
                        {Actions.map((Action, index) => {
                            return (
                                <li key={index}>
                                    <img src={Action["Icon"]}></img>
                                    <Link to={`/ffxiv/action/${Action["ID"]}/`}>{Action["Name"]}</Link>
                                </li>
                                
                            )
                        })}
                    </ul>
                    <hr className="ffxiv-actions-hr"></hr>
                    <div id="ffxiv-actions-pagination">
                        {
                            currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                        }
        
                        {
                            currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                        }
                    </div>
                </div>
            )
    }