import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function BlogPosts() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [posts, setPosts] = React.useState();
    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);


    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `blog/posts/basic?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setPosts(res.data.results);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    },[])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `blog/posts/basic?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results)
                    setPosts(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0);
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `blog/posts/basic?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data.results)
                    setPosts(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0);
                }
            })
        } else {
            console.log('No more pages')
        }
    }

    return (
        <div id="blog-posts-list-container">
            <Helmet>
                <title>Blog Posts</title>
                <meta name="description" content="Discover a diverse collection of insightful posts covering various topics. Stay informed and explore expert advice, tips, and trends. Start reading now!" />
            </Helmet>
            <h1 id="blog-posts-list-title"><i>-</i> X-05 Blog Posts <i>-</i></h1>
            <hr></hr>
            <div>
                {
                    posts?.map(post => 
                        <Link to={`/blog/post/${post.slug}/`} key={post.id} className="blog-posts-list-posts">
                            <img src={post.blog_image0}></img>
                            <div>
                                <h2 className="blog-posts-list-post-title">{post.title}</h2>
                                <hr></hr>
                                <p>{post.text_preview}</p>
                            </div>
                            <p className="blog-posts-list-post-author"><span>By {post.user_username}</span> <i>-</i> {post.date_created.slice(0, 10)}</p>
                        </Link>
                    )
                }
            </div>
            <hr></hr>
            <div id="blog-posts-list-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message={"Previous"}></RollingButton> : null
                }

                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                }
            </div>
        </div>
    )
}