import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import RollingButton from "../../components/RollingButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCat } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet-async";

export default function FfxivPets() {
    const[Pets, setPets] = React.useState([]);
    const[currentPage, setCurrentPage] = React.useState(1);
    const[totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            // api needs page num params
            await axiosInstance.get('ffxiv/pets').then(res => {
                setPets(res.data["Results"]);
                setTotalPages(res.data["Pagination"]["PageTotal"]);
                console.log(res.data["Results"]);
                console.log(res.data["Pagination"]["PageTotal"]);
                console.log(typeof res.data["Pagination"]["PageTotal"])
            });
        };
        getData();
    }, []);

    return(
        <div id="ffxiv-pets-container">
            <Helmet>
                <title>{`X-05 | FFXIV Pets`}</title>
                <meta name="description" content="Explore a comprehensive FFXIV Pets List on X-05. Discover adorable companions, unique abilities, and unlock new adventures. Find the perfect pet to accompany you on your journey in the vast world of FFXIV!" />
            </Helmet>
            <h1 id="ffxiv-pets-title"><i>-</i> FFXIV Pets <i>-</i></h1>
            <hr></hr>
            <ul>
                {Pets.map((Pet, index) => {
                    return (
                        <li key={index}>
                            <Link to={`/ffxiv/pet/${Pet["ID"]}/`}><FontAwesomeIcon icon={faCat} className="ffxiv-cat"></FontAwesomeIcon> {Pet["Name"]}</Link>
                        </li>
                    )
                })}
            </ul>

        </div>
    )

};