import React from 'react';
import axiosInstance from '../../x05Utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function MarketCompanyDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [company, setCompany] = React.useState();
    const [linkedProducts, setLinkedProducts] = React.useState([]);
    const { slug } = useParams();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `market/company/${slug}?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setCompany(res.data.results.company);
                    setLinkedProducts(res.data.results.products);
                    console.log(res.data.results.company);
                    setTotalPages(Math.ceil(res.data.count / 17)); // 17 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    }, []);

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `market/company/${slug}?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLinkedProducts(res.data.results.products);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages");
        }
    }

    const prevPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `market/company/${slug}?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLinkedProducts(res.data.results.products);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages");
        }
    }

    // Special Function for making api call to analytics and sending user to url of item
    async function sendToAnalytics(e, lproduct) {
        e.preventDefault();

        // Need to send over the foriegn object of makret item
        // console.log(code);

        const newWindow = window.open(lproduct.url, '_blank');
        if (newWindow) newWindow.opener = null;

        await axiosInstance.post(
            'analytics/market-item-click/create/', {
                market_item: lproduct.code,
            }
        ).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false);
                setAuthUser(null);
                localStorage.clear();
                window.location.reload(false);
            } else {
                console.log(res.data);
            }
        }
        )
    }

    // Need function to clean item names for displaying
    const truncatedText = (text) => {
        if (text.length > 55) {
            return text.substring(0, 52) + '...';
        } else {
            return text;
        }
    }

    return (
        <div id="market-company-detail-container">
            <Helmet>
                <title>{`X-05 | ${company?.name}`}</title>
                <meta name="description" content={company?.description.slice(0, 160)} />
            </Helmet>
            <h1 id="market-company-detail-title"><i>-</i> {company?.name} <i>-</i></h1>
            <hr></hr>
            <div id="market-company-detail-sub-info">
                <p id="market-company-detail-description">{company?.description}</p>
                <img src={company?.image}></img>
            </div>
            <hr></hr>
            <div id="market-company-detail-products">
                {
                    linkedProducts?.map(lproduct =>
                        <a 
                            className='market-company-detail-card'
                            onClick={(e) => sendToAnalytics(e, lproduct)}
                        >
                            {
                                lproduct?.image_url ?
                                    <img src={lproduct?.image_url}></img>
                                    :
                                    <img src={lproduct?.backup_image}></img>
                            }
                            <div className="market-company-detail-product-div">
                                <p className="market-company-detail-product-company">{lproduct?.company_name}</p>
                                <hr></hr>
                            </div>
                            <h2 className="market-company-detail-product-name">{truncatedText(lproduct?.name)}</h2>
                        </a>
                    )
                }
            </div>
            <hr></hr>
            <div id="market-company-detail-pagination">
                {
                    currentPage > 1 ? <RollingButton click={prevPage} message="Previous" /> : null
                }
                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                }
            </div>
        </div>
    )
}