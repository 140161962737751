import React from 'react';
import axiosInstance from '../../x05Utils';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useLogin } from '../../contexts/LoginContext';

import { useUser } from '../../contexts/UserContext';
import RollingButton from '../../components/RollingButton';
import { Helmet } from 'react-helmet-async';

export default function RecipesList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [recipes, setRecipes] = React.useState([]);
    const [nextPage, setNextPage] = React.useState("");
    
    const [totalCount, setTotalCount] = React.useState(0);

    const { search_str } = useParams();

    // Pagination needs to be reworked

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`recipes/list?q=${search_str}`).then(res => {
                setRecipes(res.data.hits);
                setTotalCount(res.data.count);
                setNextPage(res.data.next);
                console.log(res.data.count);
            });
        };
        getData();
    }, [search_str]);

    const handleNext = async () => {
        if (nextPage !== "") {
            // console.log(nextPage);
            await axiosInstance.get(`recipes/list?q=${search_str}&_cont=${nextPage}`).then(res => {
                setRecipes(res.data.hits);
                setNextPage(res.data.next);
                console.log(res.data.count);
                window.scrollTo(0, 0)
            });
        }
    }

    return (
        <div id="recipes-list-container">
            <Helmet>
                <title>{`X-05 | Recipes List | ${search_str}`}</title>
                <meta name="description" content={`Explore the latest recipes on ${search_str}. Gain valuable insights, tips, and expert advice in this comprehensive collection. Stay updated with the latest trends and developments. Start reading now!`} />
            </Helmet>
            <h1 id="recipes-list-title"><i>-</i> Recipes List <i>-</i></h1>
            <legend id="recipes-list-count">Total Recipes - {totalCount}</legend>
            <hr></hr>
            {
                recipes.map(recipe => (
                    <Link className="recipes-list-card" to={`/recipes/recipe/${recipe['uri']}/`}>
                        <img src={recipe.image}></img>
                        <div className="recipes-list-card-data">
                            <h2>{recipe.label}</h2>
                            <hr></hr>
                            {
                                recipe.cuisinetype.map(cuisine => (
                                    <p>{cuisine}</p>
                                ))
                            }
                        </div>
                        <div className="recipes-list-card-sub-data">
                            {
                                recipe.healthlabels.map(healthlabel => (
                                    <p>{healthlabel}</p>
                                ))
                            }
                            {
                                recipe.dietlabels.map(dietlabel => (
                                    <p>{dietlabel}</p>
                                ))
                            }
                        </div>
                    </Link>
                ))
            }
            <div id="recipes-list-pagination">
                {
                    nextPage !== "" ? <RollingButton click={handleNext} message="Next" /> : null
                }
            </div>
                
        </div>
    )
}