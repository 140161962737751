import React, { useContext } from "react";
import axiosInstance from "../../x05Utils";
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";


export default function BlogArticleDetail() {
    const { slug } = useParams();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [article, setArticle] = React.useState()

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `blog/article/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setArticle(res.data);
                }
            })
        }
        getData();
    }, [slug])

    // Should be its own component
    const renderTextWithLinksAndBreaks = (text) => {
        // Check if text was provided
        if (!text) return null;
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // const parts = text.split(urlRegex);
    
        const paragraphs = text.split('\\n');

        console.log(paragraphs);
        // loop through the paragraphs and remove any remaining \ characters
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i] = paragraphs[i].replace(/\\/g, '');
            // replace tabs with spaces here if needed
        }
        
        
    
        return paragraphs.map((paragraph, index) => (
            // we still need to remove the random \ from the text after replacing the \n characters
            // also if the text contains a link the \n characters are not replaced with <br> tags
            <React.Fragment key={index}>
                {index !== 0 && <br />} {/* Add <br> except for the first line */}
                {paragraph.split(urlRegex).map((part, partIndex) => {
                    if (part.match(urlRegex)) {
                        return (
                            <a key={partIndex} href={part} target="_blank" rel="noopener noreferrer">
                                {part}
                            </a>
                        );
                    }
                    return <span key={partIndex}>{part}</span>;
                })}
            </React.Fragment>
        ));
    };
    

    return(
        <div id="blog-article-detail-container">
            <Helmet>
                <title>{`${article?.title}`}</title>
                <meta name="description" content={article?.text.slice(0, 160)} />
            </Helmet>
            <h1 id="blog-article-detail-title"><i>-</i> {article?.title} <i>-</i></h1>
            <hr></hr>
            <div id="blog-article-detail-sub-data">
                <p id="blog-article-detail-date">{article?.date_created.slice(0, 10)}</p>
                <p id="blog-article-detail-user">By <i>-</i> {article?.user_username}</p>
            </div>
            <hr></hr>
            <img src={article?.image}></img>
            <div>
                <pre id="blog-article-detail-text">{renderTextWithLinksAndBreaks(article?.text)}</pre>
            </div>
        </div>
    )
}