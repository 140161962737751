import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { mainUrl, getCookie } from "../../x05Utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from "react-router-dom";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import jwt_decode from "jwt-decode";
import RollingButton from "../../components/RollingButton";
import { Link } from 'react-router-dom';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import CSRFToken from "../../components/CSRFToken";
import { Helmet } from "react-helmet-async";

export default function Login() {
    const[username, setUsername] = useState();
    const[password, setPassword] = useState();

    const location = useLocation();
    const navigate = useNavigate();

    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    let csrftoken = getCookie('csrftoken')

    async function login(e) {
        localStorage.clear();
        e.preventDefault();
        await axios({
            method: 'POST', 
            url: mainUrl.concat('token/'), 
            data: JSON.stringify({
                username: username, 
                password: password
            }),
            headers: { "Content-Type": "application/json;charset=UTF-8", 
                        "X-CSRFToken": csrftoken
            }
        }).then((response) => {
            localStorage.setItem('access', response.data.access)
            localStorage.setItem('refresh', response.data.refresh);
            setLoggedIn(true);
            // set auth user to value from given jwt at login id/user/code
            const jwtUser = jwt_decode(response.data.refresh)['user_id']
            console.log(jwtUser)
            console.log(typeof jwtUser)
            //
            setAuthUser(authUser => jwtUser); //error is coming from this line Auth User is remaining null on most tries
            //
            // this console.log will not work all of the time because it is right after the set
            // console.log('Welcome ' + authUser)

            // navigate doesnt work after refresh
            navigate(
                location?.state?.previousUrl
                    ? location.state.previousUrl
                    : '/'
            );

            // Refresh Page to deal with auth delay bug after logging in
            window.location.reload(true);
            
        })
    }

    return (
        <div id="account-login-container">
            <Helmet>
                <title>{`X-05 | Login Page`}</title>
                <meta name="description" content="Login to our platform and access exclusive features. Securely manage your account, stay connected, and enjoy a seamless user experience. Sign in and explore our offerings!" />
            </Helmet>
            <h1 id="account-login-title"><i>-</i> X-05 Login <i>-</i></h1>
            <hr></hr>
            { loggedIn ? <p>You're already logged in</p> : <form onSubmit={login}>
                <CSRFToken></CSRFToken>
                <label for="username"><FontAwesomeIcon icon={faUser} className="account-login-icon"/> Username</label>
                <input type="username" name="username" placeholder="" value={username} onChange={(e) => {
                    setUsername(e.target.value)
                }}>
                </input>
                <label for="password"><FontAwesomeIcon icon={faLock} className="account-login-icon"/> Password</label>
                <input type="password" name="password" placeholder="" value={password} onChange={(e) => {
                    setPassword(e.target.value)
                }}>
                </input>
                <hr></hr>
                <RollingButton message="LOGIN" type="submit"></RollingButton>
                <Link to='/accounts/register/'>Don't have an account?</Link>
            </form>}
            { authUser ? <p>{`hello ${authUser}`}</p> : null }
        </div>
    )
}