import React, { useState, useEffect } from "react";
import axiosInstance from "../../x05Utils";


function CardList() {
  const [cards, setCards] = useState([]);
  // DO NOT USE THIS COMPONENT IT IS GETTING IMAGES FROM API NOT X-05 IMAGES
  

  return (
    <div className="yugioh-card-list">
      {cards.map((card) => (
        <img src={card.card_images[0].image_url_small} alt={card.name} className="yugioh-card-list-card"/>
      ))}
    </div>
  );
}

export default CardList;