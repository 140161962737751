import React from "react";
import axiosInstance from "../../x05Utils";
import { Link, useParams } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";

export default function YoutubeSVideoDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [video, setVideo] = React.useState({});
    const { slug } = useParams();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`youtube/s/video/${slug}/`).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setVideo(res.data);
                }
            })
        }
        getData();
    }, [slug])

    return (
        <div id="youtube-s-video-detail-container">
            <Helmet>
                <title>{`X-05 | Youtube | Supported | ${video?.title}`}</title>
                <meta name="description" content={`Watch Supported Youtube ${video?.title} on X-05. Discover engaging content, insightful perspectives, and stay informed. Start watching now`} />
            </Helmet>
            <h1 id="youtube-s-video-detail-title"><i>-</i> X-05 Supported YouTube Video <i>-</i></h1>
            <hr></hr>
            <div id="youtube-s-video-detail-video">
                <h2>{video?.title}</h2>
                <hr></hr>
                <div id="youtube-s-video-detail-sub">
                    <p id="youtube-s-video-detail-date">{video?.date_created?.slice(0, 10)}</p>
                    <p id="youtube-s-video-detail-channel">By <i>-</i> {video?.channel_name}</p>
                </div>
                <hr></hr>
                <iframe src={`https://www.youtube.com/embed/${video?.video_id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    )
}