import React from "react";
import axiosInstance, { ffxivUrl } from "../../x05Utils";
import RollingButton from "../../components/RollingButton";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";


export default function FfxivCraftActions() {
    
        const[craftActions, setCraftActions] = React.useState([]);
        const[currentPage, setCurrentPage] = React.useState(1);
        const[totalPages, setTotalPages] = React.useState(1);
    
        React.useEffect(() => {
            const getData = async () => {
                // api needs page num params
                await axiosInstance.get('ffxiv/craftactions').then(res => {
                    setCraftActions(res.data["Results"]);
                    setTotalPages(res.data["Pagination"]["PageTotal"]);
                    console.log(res.data["Results"]);
                    console.log(res.data["Pagination"]["PageTotal"]);
                    console.log(typeof res.data["Pagination"]["PageTotal"])
                });
            };
            getData();
        }, []);
    
        // need a function to make api call to the next page.   function is written by co-pilot
        const nextPage = async () => {
            if (currentPage < totalPages) {
                await axiosInstance.get(`ffxiv/craftactions?page=${currentPage + 1}`).then(res => {
                    setCraftActions(res.data["Results"]);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                });
            } else {
                console.log("No more pages");
            }
        };
    
        // need a function to make api call to the previous page.   function is written by co-pilot
        const prevPage = async () => {
            if (currentPage > 1) {
                await axiosInstance.get(`ffxiv/craftactions?page=${currentPage - 1}`).then(res => {
                    setCraftActions(res.data["Results"]);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                });
            } else {
                console.log("No more pages");
            }
        };
        return (
            <div id="ffxiv-craftactions-container">
                <Helmet>
                    <title>{`X-05 | FFXIV Craft Actions`}</title>
                    <meta name="description" content="Master the art of crafting with FFXIV Craft Actions on X-05. Learn essential skills, discover efficient recipes, and create valuable items. Level up your crafting game and become a renowned artisan in the realm of FFXIV!" />
                </Helmet>
                <h1 id="ffxiv-craftactions-title"><i>-</i> FFXIV Craft Actions <i>-</i></h1>
                <hr className="ffxiv-craftactions-hr"></hr>
                <ul>
                    {craftActions.map((craftAction, index) => {
                        return (
                            <li key={index}>
                                <img src={craftAction["Icon"]}></img>
                                <Link to={`/ffxiv/craftaction/${craftAction["ID"]}/`}>{craftAction["Name"]}</Link>
                            </li>
                            
                        )
                    })}
                </ul>
                <hr className="ffxiv-craftactions-hr"></hr>
                <div id="ffxiv-craftactions-pagination">
                    {
                        currentPage > 1 ? <RollingButton click={prevPage} message={"Previous"}></RollingButton> : null
                    }
    
                    {
                        currentPage < totalPages ? <RollingButton click={nextPage} message={"Next"}></RollingButton> : null
                    }
                </div>
            </div>
        )
    }