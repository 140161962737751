import React from "react";
import axios from "axios";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBomb } from '@fortawesome/free-solid-svg-icons';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";


export default function PollsQuestions() {
    const[questions, setQuestions] = React.useState([]);
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`polls/questions/`).then(res => {
                if (res.request.status === 401) {
                    setLoggedIn(false)
                    setAuthUser(null)
                    localStorage.clear()
                    window.location.reload(false);
                } else {
                    setQuestions(res.data);
                    console.log(res.data);
                }
            });
        };
        getData();
    }, []);

    return (
        <div id="polls-questions-container">
            <Helmet>
                <title>{`X-05 | Polls Questions`}</title>
                <meta name="description" content="Explore a wide range of thought-provoking poll questions. Engage in meaningful discussions, share your perspective, and discover diverse opinions." />
            </Helmet>
            <h1 id="polls-questions-title"><i>-</i> Poll Questions <i>-</i></h1>
            <hr></hr>
            {
                questions ? 
                    <ul>
                        {
                            questions.map(question => 
                                <li key={question.pk} className="question-li">
                                    <Link to={`/polls/question/${question.slug}/`}><FontAwesomeIcon icon={faBomb} className="question-bolts"/> {question.linked_category} <i>-</i> {question.question_topic} <i>-</i> {question.publish_date.slice(0, 10)} <FontAwesomeIcon icon={faBomb} className="question-bolts"/></Link>
                                    <hr></hr>
                                </li>
                                )
                        }
                    </ul>
                :
                null
            }
        </div>
    )
}