import React, {useState} from "react";
import axiosInstance, {getCookie} from "../../x05Utils";

import axios from "axios";
import { useLogin } from "../../contexts/LoginContext";

import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import CSRFToken from "../../components/CSRFToken";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";


export default function EmailListSubscriberCreate() {
    const [email, setEmail] = useState("");

    const [loggedIn, setLoggedIn] = useState(false);
    const [authUser, setAuthUser] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    let csrftoken = getCookie('csrftoken');

   async function subscribeCreate(e) {
        e.preventDefault();
        console.log(email)
        // Might need axiosInstance instead of axios since user names need to be collected if they exist
        const user_agent = navigator.userAgent;
        const referrer = document.referrer;
        console.log(user_agent)
        console.log(referrer)
        
        await axiosInstance.post(`email-list/subscriber/create/`, {
            email: email,
            user_agent: user_agent,
            referrer: referrer,
        }).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false);
                setAuthUser(null);
                console.log("401 was hit attempting token refresh")
                console.log(res.status)
                window.location.reload();
            }
            // 201 === created
            else if (res.status === 201) {
                console.log("Subscriber created successfully")
                console.log(res.status)
                navigate(
                    location?.state?.previousUrl
                        ? location.state.previousUrl
                        : "/"
                )
            }
        });
    }

    return(
        <>
            <Helmet>
                <title>{`X-05 | Subscribe to Email List`}</title>
                <meta name="description" content="Subscribe to X-05.com's exclusive email list for insider updates, special offers, and the latest trends. Stay informed with just a click!" />
            </Helmet>
            <div id="email-list-subscriber-create-container">
                <h1 id="email-list-subscriber-create-title"><i>-</i> Join our mailing list. <i>-</i></h1>
                <hr></hr>
                <form onSubmit={subscribeCreate} id="email-list-subscriber-create-form">
                    <CSRFToken />
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email-list-subscriber-create-email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <RollingButton type="submit" message="Subscribe"></RollingButton>
                </form>
                <p id="email-list-subscriber-create-info">
                    Subscribe to X-05's exclusive email list and stay connected with insider updates, special offers, latest blog articles, and feature releases. 
                    Our emails deliver valuable content directly to your inbox, ensuring you never miss out on important information. Join our community and be the first to know about new releases, promotions, events, and more. Sign up today and stay informed with just a click!

                </p>
            </div>
        </>
    )
}