import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDroplet } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";

export default function PollsCategories() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [categories, setCategories] = React.useState()

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                'polls/categories/'
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setCategories(res.data);
                }
            })
        }
        getData();
    }, [])

    return(
        <div id="polls-categories-container">
            <Helmet>
                <title>{`X-05 | Polls Categories`}</title>
                <meta name="description" content="Explore diverse poll categories and engage in insightful discussions. Cast your vote and contribute to the latest trends and opinions. Discover a wide range of topics and make your voice heard." />
            </Helmet>
            <h1 id="polls-categories-title"><i>-</i> X-05 Polls Categories <i>-</i></h1>
            <hr></hr>
            <ul>
                {
                    categories?.map(cate => 
                        <li>
                            <Link className="polls-category-link" to={`/polls/category/${cate.slug}`}><FontAwesomeIcon icon={faDroplet} className="polls-category-droplets"></FontAwesomeIcon> {cate.name} <FontAwesomeIcon icon={faDroplet} className="polls-category-droplets"></FontAwesomeIcon></Link>
                            <hr></hr>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}