import React, { useContext, useState, createContext } from 'react'
import jwt_decode from "jwt-decode";


const AuthContext = createContext();


export function useUser() {
    return useContext(AuthContext)
}

export function UserProvider({children}) {
    // https://stackoverflow.com/questions/42141393/react-js-error-invalid-attempt-to-destructure-non-iterable-instance
    const [authUser, setAuthUser] = useState(
        localStorage.access ? jwt_decode(localStorage.getItem('refresh'))['user_id'] : null
    );

    function changeAuthUser(value) {
        setAuthUser(value)
        if (value === null) {
            localStorage.clear();
        }
    }

    return (
        <AuthContext.Provider value={[authUser, changeAuthUser]}>
            {children}
        </AuthContext.Provider>
    )
}