import React from "react";
import axios from "axios";
import axiosInstance from "../../x05Utils";
import { Helmet } from "react-helmet-async";


// need to reqrite this component to use the new API and axiosInstance
export default function PollsHome() {
    const[questions, setQuestions] = React.useState([]);
    const[answers, setAnswers] = React.useState([]);

    React.useEffect(() => {
        
    }, []);

    return (
        <div id="polls-home-container">
            <Helmet>
                <title>{`X-05 | Polls`}</title>
                <meta name="description" content="Discover engaging polls on a variety of topics. Voice your opinion, see real-time results, and join the conversation. Stay informed with the latest trends and insights." />
            </Helmet>
            <h1 id="polls-home-title"><i>-</i> Polls Home <i>-</i></h1>
            
        </div>
    )
}