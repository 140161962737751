import React from "react";
import axiosInstance from "../../x05Utils";
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";


export default function ShortStoryDetail() {
    const { slug } = useParams();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [story, setStory] = React.useState();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `stories/short-story/${slug}/`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStory(res.data);
                }
            })
        }
        getData();
    }, [slug])

    // Should be its own compenent
    const renderTextWithLinksAndBreaks = (text) => {
        // Check if text was provided
        if (!text) return null;
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // const parts = text.split(urlRegex);
    
        const paragraphs = text.split('\\n');

        console.log(paragraphs);
        // loop through the paragraphs and remove any remaining \ characters
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i] = paragraphs[i].replace(/\\/g, '');
            // replace tabs with spaces here if needed
        }
        
        
    
        return paragraphs.map((paragraph, index) => (
            // we still need to remove the random \ from the text after replacing the \n characters
            // also if the text contains a link the \n characters are not replaced with <br> tags
            <React.Fragment key={index}>
                {index !== 0 && <br />} {/* Add <br> except for the first line */}
                {paragraph.split(urlRegex).map((part, partIndex) => {
                    if (part.match(urlRegex)) {
                        return (
                            <a key={partIndex} href={part} target="_blank" rel="noopener noreferrer">
                                {part}
                            </a>
                        );
                    }
                    return <span key={partIndex}>{part}</span>;
                })}
            </React.Fragment>
        ));
    };

    return (
        <>
            <Helmet>
                <title>{`short story | ${story?.title}`}</title>
                <meta name="description" content={story?.text.slice(0, 160)} />
            </Helmet>
            <div id="stories-short-story-detail-container">
                <h1 id="stories-short-story-detail-title"><i>-</i> {story?.title} <i>-</i></h1>
                <hr></hr>
                <div id="stories-short-story-detail-sub-data">
                    <p id="stories-short-story-detail-date">{story?.date_created.slice(0, 10)}</p>
                    <p id="stories-short-story-detail-user"><i>-</i> By {story?.user_username} <i>-</i></p>
                </div>
                <hr></hr>
                <img src={story?.image}></img>
                <div>
                    <pre id="stories-short-story-detail-text">{renderTextWithLinksAndBreaks(story?.text)}</pre>
                </div>
            </div>
        
        </>
    )
}