import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";
import RollingButton from "../../components/RollingButton";


export default function MarketCollectionList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [collections, setCollections] = React.useState()

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `market/collections?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setCollections(res.data.results);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 100 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    }, [])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `market/collections?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setCollections(res.data.results);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0);
                }
            }
            )

        }
    }
    
    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `market/collections?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setCollections(res.data.results);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0);
                }
            }
            )

        }
    }


    return (
        <>
            <Helmet>
                <title>{`X-05 | Market Collections`}</title>
                <meta name="description" content="Discover the Finest Selection of Products at X-05 - Your One-Stop Shop for Quality and Variety! Explore an Exquisite Collection of Products, from Trendy Gadgets to Stylish Accessories. Shop Now and Elevate Your Lifestyle with X-05!" />
            </Helmet>
            <div id="market-collection-list-container">
                <h1 id="market-collection-list-title"><i>-</i> Market Item Collections <i>-</i></h1>
                <hr></hr>
                <div id="market-collection-list-content-div">
                    {
                        collections?.map(collection => 
                            <Link className="market-collection-list-content" to={`/market/collection/${collection?.slug}/`}>
                                <img src={collection?.thumbnail}></img>
                                <div>
                                    <h2 className="market-collection-list-name">{collection?.name}</h2>
                                    <hr></hr>
                                    <p>{collection.description}</p>
                                </div>
                                <p className="market-collection-list-items-count">{collection?.get_items_count} - Items</p>
                            </Link>
                        )
                    }
                </div>
            </div>
            <hr></hr>
            <div id="market-collection-list-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message="Previous" /> : null
                }
                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                }
            </div>
        </>
    )
}