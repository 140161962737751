import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import RollingButton from "../../components/RollingButton";

// The design of this page is very important.
// One call to action and the button needs to be very inviting.
// Images/videos on the left and text/button on the right.

export default function MarketLandingDetail() {
    const { slug } = useParams();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [landing, setLanding] = React.useState();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`market/landing/${slug}/`).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLanding(res.data);
                    console.log(res.data);
                }
            });
        }
        getData();
    }, [slug]);

    // Should be its own component
    const renderTextWithLinksAndBreaks = (text) => {
        // Check if text was provided
        if (!text) return null;
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // const parts = text.split(urlRegex);
    
        const paragraphs = text.split('\\n');

        console.log(paragraphs);
        // loop through the paragraphs and remove any remaining \ characters
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i] = paragraphs[i].replace(/\\/g, '');
            // replace tabs with spaces here if needed
        }
        
        
    
        return paragraphs.map((paragraph, index) => (
            // we still need to remove the random \ from the text after replacing the \n characters
            // also if the text contains a link the \n characters are not replaced with <br> tags
            <React.Fragment key={index}>
                {index !== 0 && <br />} {/* Add <br> except for the first line */}
                {paragraph.split(urlRegex).map((part, partIndex) => {
                    if (part.match(urlRegex)) {
                        return (
                            <a key={partIndex} href={part} target="_blank" rel="noopener noreferrer">
                                {part}
                            </a>
                        );
                    }
                    return <span key={partIndex}>{part}</span>;
                })}
            </React.Fragment>
        ));
    };

    async function sendToAnalytics(e, landing) {
        e.preventDefault();

        // Need to send over the foriegn object of makret item
        // console.log(code);

        await axiosInstance.post(
            'analytics/market-landing-click/create/', {
                market_landing: landing.code,
            }
        ).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false);
                setAuthUser(null);
                localStorage.clear();
                window.location.reload(false);
            } else {
                console.log(res.data);
            }
        }
        )

        // Needs to be bellow api call or else api call will never happen
        const newWindow = window.open(landing.url, '_blank');
        if (newWindow) newWindow.opener = null;
    }

    // Need a very inviting call to action button... something that is almost impossible to not click on.
    return (
        <>
            <Helmet>
                <title>{`${landing?.name}`}</title>
                <meta name="description" content={landing?.description.slice(0, 160)}/>
            </Helmet>
            <div id="market-landing-detail-container">
                <h1 id="market-landing-detail-title"><i>-</i> {landing?.name} <i>-</i></h1>
                <hr></hr>
                <div id="market-landing-detail-content">
                    <div id="market-landing-detail-content-left">
                        {
                            landing?.video_url ?
                                <iframe src={landing?.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            :
                                null
                        }
                        {
                            landing?.image_url ?
                                <img src={landing?.image_url}/>
                                :
                                <img src={landing?.backup_image}/>
                        }
                    </div>
                    <div id="market-landing-detail-content-right">
                        <pre id="market-landing-detail-text">{renderTextWithLinksAndBreaks(landing?.description)}</pre>
                        <hr id="market-landing-detail-container-right-hr"></hr>
                        <RollingButton
                            message="View"
                            click={(e) => sendToAnalytics(e, landing)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}