import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";

import { useUser } from "../../contexts/UserContext";
import { Helmet } from "react-helmet-async";


export default function RecipesDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [recipe, setRecipe] = React.useState({});
    
    const { id } = useParams();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(`recipes/recipe?id=${id}`).then(res => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                } else {
                    setRecipe(res.data);
                    console.log(res.data);
                }
            });
        }
        getData();
    }, []);

    return (
        <div id="recipes-detail-container">
            <Helmet>
                <title>{`X-05 | ${recipe?.label}`}</title>
                <meta name="description" content={`Discover the delicious flavors of ${recipe?.label}. Step-by-step instructions, ingredients, and tips to create a memorable culinary experience.`} />
            </Helmet>
            <h1 id="recipes-detail-title"><i>-</i> Recipe Detail <i>-</i></h1>
            <hr></hr>
            <div id="recipes-detail-card">
                <h2>{recipe?.label}</h2>
                <div id="recipes-detail-data">
                    <div id="recipes-detail-data-left">
                        <h3>Diet and Health</h3>
                        {
                            recipe?.dietlabels ?
                                <div id="recipes-detail-dietlabels">
                                    {
                                        recipe.dietlabels.map(dietlabel => (
                                            <p>{dietlabel}</p>
                                        ))
                                    }
                                </div>
                            : null
                        }
                        {
                            recipe?.healthlabels ?
                                <div id="recipes-detail-healthlabels">
                                    {
                                        recipe.healthlabels.map(healthlabel => (
                                            <p>{healthlabel}</p>
                                        ))
                                    }
                                </div>
                            : null
                        }
                    </div>
                    <div id="recipes-detail-center">
                        <img src={recipe?.image}></img>
                        <legend>Source - {recipe?.source}</legend>
                        { recipe?.url ?
                            <a href={recipe?.url} target="_blank" rel="noopener noreferrer">{recipe?.url.substring(0, 27)}</a> 
                        : null }
                    </div>
                    <div id="recipes-detail-data-right">
                        <h3>Ingredients</h3>
                        {
                            recipe?.ingredients ?
                                <div id="recipes-detail-ingredients">
                                    {
                                        recipe.ingredients.map(ingredient => (
                                            <p>{ingredient}</p>
                                        ))
                                    }
                                </div>
                            : null
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}