import React from "react";
import axiosInstance from "../../x05Utils";
import { useParams } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";

// React Component for PokemonTCGCard Detail Page
export default function PokemonTCGCard() {
    const { set_id } = useParams();
    const { card_id } = useParams();

    const [card, setCard] = React.useState({});

    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `pokemontcg/card?set_id=${set_id}&card_id=${card_id}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setCard(res.data);
                    console.log(res.data);
                }
            })
        };
        getData();
    }, [set_id, card_id]);

    return (
        <div id="pokemontcg-card-container">
            <Helmet>
                <title>{`X-05 | ${card?.name}`}</title>Discover the details an
                <meta name="description" content={`Discover in-depth details of ${card?.name} and other Pokémon cards on our platform. Explore card rarities, abilities, and stats for a complete Pokémon experience. Stay updated with the latest card releases and enhance your Pokémon card collection with expert insights.`} />
            </Helmet>
            <h1 id="pokemontcg-card-title"><i>-</i> Card Details <i>-</i></h1>
            <hr></hr>
            <div id="pokemontcg-card-info">
                <legend>Artist - {card?.artist}</legend>
                <h2>{card?.name}</h2>
                <div id="pokemontcg-card-sub-info">
                    <h3>Sub Data</h3>
                    {card?.hp ? <p>HP - {card?.hp}</p> : null}
                    {card?.level ? <p>Level - {card?.level}</p> : null}
                    {card?.evolvesFrom ?<p>Evolves From - {card?.evolvesFrom}</p>: null}
                    {card?.rarity ? <p>Rarity - {card?.rarity}</p> : null}
                </div>
                <div id="pokemontcg-card-abilities">
                    <h4>Abilities</h4>
                    {
                        card?.abilities?.map((ability) => {
                            return (
                                <div>
                                    <h5>{ability.name}</h5>
                                    <p>{ability.text}</p>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}