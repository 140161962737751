import React from "react";
import axiosInstance, { mainUrl } from "../../x05Utils";
import { Link, useParams } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import { Helmet } from "react-helmet-async";


export default function TicketResponseDetail() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[ticketResponse, setTicketResponse] = React.useState()

    const { code } = useParams();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `tickets/responses/${code}/`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setTicketResponse(res.data);
                }
            })
        }
        getData();
    }, [])

    return(
        <div id="ticket-response-detail-container">
            <Helmet>
                <title>{`X-05 | Ticket Response Details`}</title>
                <meta name="description" content={`Ticket response details for ticket ${ticketResponse?.ticket_code}.`} />
            </Helmet>
            <h1><i>-</i> Ticket Response Details <i>-</i></h1>
            <hr></hr>
            <div id="ticket-response-detail-sub">
                <p>{ticketResponse?.created_at.slice(0, 10)}</p>
                <p>By <i>-</i> {ticketResponse?.user_username}</p>
            </div>
            <hr></hr>
            <p id="ticket-response-detail-response">
                {ticketResponse?.response}
            </p>
        </div>
    )

}