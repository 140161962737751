import React from 'react';
import axiosInstance from '../../x05Utils';
import { Link } from 'react-router-dom';
import { useLogin } from '../../contexts/LoginContext';
import { useUser } from '../../contexts/UserContext';

import { Helmet } from 'react-helmet-async';

export default function YoutubeSChannelsList() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [channels, setChannels] = React.useState()

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                'youtube/s/channels/'
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setChannels(res.data);
                }
            })
        }
        getData();
    }, [])

    return (
        <div id="youtube-s-channels-list-container">
            <Helmet>
                <title>{`X-05 | YouTube Supported Channels List`}</title>
                <meta name="description" content="Discover the best YouTube channels supported by X-05. Get entertained, educated, and inspired by top creators in various niches. Start exploring now!" />
            </Helmet>

            <h1 id="youtube-s-channels-list-title"><i>-</i> X-05 YouTube Supported Channels <i>-</i></h1>
            <hr></hr>
            <div id="youtube-s-channels-list-rows">
                {
                    channels?.map(channel =>
                        <Link to={`/youtube/s/channel/${channel.slug}/`} className="youtube-s-channels-list-card">
                            <img src={channel.image}></img>
                            <legend className="youtube-s-channels-list-name">{channel.name.slice(0, 7)}</legend>
                            <legend className="youtube-s-channels-list-category">{channel.category_name.slice(0, 7)}</legend>
                        </Link>
                    )
                }
            </div>
        </div>
    )
}