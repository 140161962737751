import React from "react";
import axiosInstance, { mainUrl } from "../../x05Utils";
import { Link, useParams } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import axios from "axios";
import { Helmet } from "react-helmet-async";

export default function TicketResponsesList() {
    const[loggedIn, setLoggedIn] = useLogin();
    const[authUser, setAuthUser] = useUser();
    const[ticket, setTicket] = React.useState()
    const[responses, setResponses] = React.useState()

    const { ticket_code } = useParams();

    document.title = 'X-05 | Ticket Responses List';

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `tickets/${ticket_code}/`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setTicket(res.data);
                }
            })
            await axios.get(
                `/api/tickets/responses/${ticket_code}/`
            ).then((res) => {
                if (res.status === 401) {
                    console.log('Unauthorized')
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setResponses(res.data);
                }
            })
        }
        getData();
    }, [ticket_code])

    return(
        <div id="ticket-responses-list-container">
            <Helmet>
                <title>{`X-05 | Ticket Responses List`}</title>
                <meta name="description" content={`Ticket responses list for ticket ${ticket?.code}.`} />
            </Helmet>
            <h1 id="ticket-responses-list-title"><i>-</i> X-05 Ticket Responses List <i>-</i></h1>
            <hr></hr>
            <div id="ticket-responses-ticket-details">
                <h2>Ticket Details</h2>
                <p><b>Code:</b> {ticket?.code}</p>
                <p><b>Category:</b> {ticket?.category}</p>
                <p><b>Title:</b> {ticket?.title}</p>
                <p><b>Description:</b> {ticket?.description}</p>
            </div>
            <div id="ticket-responses-list">
                <h3>Responses</h3>
                <ul>
                    {
                        responses?.map(response =>
                            <li>
                                <Link to={`/responses/${response.code}/`} className="ticket-responses-list-content">{response.category} - {response.title}</Link>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}