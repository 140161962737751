import React from "react";
import axiosInstance from "../../x05Utils";
import { Link, useParams } from 'react-router-dom';
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import { Helmet } from "react-helmet-async";

export default function YoutubeSChannelDetail() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();
    const [channel, setChannel] = React.useState()
    const [linkedVideos, setLinkedVideos] = React.useState([])
    const { slug } = useParams();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `youtube/s/channel/${slug}?page=${currentPage}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data);

                    setChannel(res.data.results.channel);
                    setLinkedVideos(res.data.results.videos);

                    setTotalPages(Math.ceil(res.data.count / 17)); // 20 should be obtained through a dynamic variable
                    console.log(Math.ceil(res.data.count / 17)); // 20 should be obtained through a dynamic variable
                }
            })
        }
        getData();
    }, [slug])

    const nextPage = async () => {
        if (currentPage < totalPages) {
            await axiosInstance.get(
                `youtube/s/channel/${slug}?page=${currentPage + 1}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLinkedVideos(res.data.results.videos);
                    setCurrentPage(currentPage + 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages");
        }
    };

    const previousPage = async () => {
        if (currentPage > 1) {
            await axiosInstance.get(
                `youtube/s/channel/${slug}?page=${currentPage - 1}`
            ).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    setLinkedVideos(res.data.results.videos);
                    setCurrentPage(currentPage - 1);
                    window.scrollTo(0, 0)
                }
            })
        } else {
            console.log("No more pages");
        }
    };

    return(
        <div id="youtube-s-channel-detail-container">
            <Helmet>
                <title>{`X-05 | Youtube Supported | ${channel?.name}`}</title>
                <meta name="description" content={`Discover in-depth insights about ${channel?.name} on X-05. Explore their captivating content, engaging videos, and subscribe to stay updated. Start your YouTube journey now!`} />
            </Helmet>
            <h1 id="youtube-s-channel-detail-title"><i>-</i> {channel?.name} <i>-</i></h1>
            <hr></hr>
            <div id="youtube-s-channel-detail-sub-info">
                <p>{channel?.category_name}</p>
                <img src={channel?.image}></img>
            </div>
            <hr></hr>
            <div id="youtube-s-channel-detail-videos">
                {
                    linkedVideos?.map(lvideo =>
                        <Link to={`/youtube/s/video/${lvideo.slug}/`} className="youtube-s-channel-detail-card">
                            <img src={lvideo.thumbnail}></img>
                            <hr></hr>
                            <h2 className="youtube-s-channel-detail-video-title">{lvideo.title.slice(0, 15)}</h2>
                        </Link>
                    )
                }
            </div>
            <hr></hr>
            <div id="youtube-s-channel-detail-pagination">
                {
                    currentPage > 1 ? <RollingButton click={previousPage} message="Previous" /> : null
                }
                {
                    currentPage < totalPages ? <RollingButton click={nextPage} message="Next" /> : null
                }
            </div>
        </div>
    )
}