// Using a bootstap solution for now

import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';


// Note for 7/12/23
// A CUSTOM VIEW AND SERIALIZER IS NEEDED TO STOP THE EMAILS FROM BEING SHOWN IN THE API RESPONSE


export default function X05Alert(props) {

    // Alert.link should be used in props.message from the parent component to make it dynamic
    return (
        <Alert variant={props.variant} key={props.variant} className="x-alert" id={props.id}>
            {
                props.link ?
                <Alert.Link as={Link} to={props.link} id={props.linkId}>{props.linkText}</Alert.Link>
                :
                props.message
            }
        </Alert>
    )
}