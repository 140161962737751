import React from "react";
import axios from "axios";
import { axiosConfig, mainUrl } from "../../x05Utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight, faHandPointLeft } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from "react-helmet-async";

export default function FfxivMaintenance() {
    const[maintenance, setMaintenance] = React.useState([]);

    React.useEffect(() => {
        const getData = async () => {
            await axios.get(mainUrl.concat('ffxiv/maintenance/'), axiosConfig).then(res => {
                setMaintenance(res.data);
            });
        };
        getData();
    }, []);

    return(
        <div id="ffxiv-maintenance-container">
            <Helmet>
                <title>{`X-05 | FFXIV Maintenance`}</title>
                <meta name="description" content="Stay updated with FFXIV Maintenance Schedule on X-05. Plan your gaming sessions, prepare for updates, and ensure uninterrupted adventures. Get the latest maintenance information, patch notes, and optimize your FFXIV experience!" />
            </Helmet>
            <h1 id="ffxiv-maintenance-title"><i>-</i> FFXIV Maintenance <i>-</i></h1>
            <table id="ffxiv-maintenance-table">
                <tr id="ffxiv-maintenance-table-titles">
                    <th>Title</th>
                    <th>Date</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>URL</th>
                </tr>
                {
                    maintenance.map(mainten => 
                        <tr className="ffxiv-maintenance-data">
                            <td>{mainten.title}</td>
                            <td>{mainten.time.slice(0, 10)}</td>
                            <td>{mainten.start}</td>
                            <td>{mainten.end}</td>
                            <td>
                                <a href={mainten.url}><FontAwesomeIcon icon={faHandPointRight} className="ffxiv-point" target="_blank" rel="noopener noreferrer"/> {mainten.url} <FontAwesomeIcon icon={faHandPointLeft} className="ffxiv-point"/></a>
                            </td>
                        </tr>
                        )
                }
            </table>
        </div>
    )
}