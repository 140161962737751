import React from 'react';
import axiosInstance from '../../x05Utils';
import { Link } from 'react-router-dom';
import { useLogin } from '../../contexts/LoginContext';
import { useUser } from '../../contexts/UserContext';

import RollingButton from '../../components/RollingButton';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

export default function YoutubeSHome() {
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    const [randomVideo, setRandomVideo] = React.useState();
    const [randomVideos, setRandomVideos] = React.useState();
    const [randomCategories, setRandomCategories] = React.useState();
    const [randomChannels, setRandomChannels] = React.useState();
    
    React.useEffect(() => {
        const getData = async () => {
            await Promise.all([
                axiosInstance.get(
                    'youtube/s/video/random/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setRandomVideo(res.data)
                    }
                }
                ),
                axios.get(
                    '/api/youtube/s/videos/random-five/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setRandomVideos(res.data)
                    }
                }
                ),
                axios.get(
                    '/api/youtube/s/categories/random-five/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setRandomCategories(res.data)
                    }
                }
                ),
                axios.get(
                    '/api/youtube/s/channels/random-five/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        console.log("401 was hit attempting token refresh")
                        console.log(res.status)
                        window.location.reload(false);
                    } else {
                        setRandomChannels(res.data)
                    }
                }
                )
            ])
        }
        getData();
    }, [])

    return (
        <div id="youtube-s-home-container">
            <Helmet>
                <title>{`X-05 | Youtube Supported Home`}</title>
                <meta name="description" content="Youtube Creators and videos Supported by X-05." />
            </Helmet>
            <h1 id="youtube-s-home-title"><i>-</i> X-05 Youtube Supported Home <i>-</i></h1>
            <hr></hr>
            <ul id="youtube-s-home-nav">
                <li>
                    <Link to="/youtube/categories/">
                        <RollingButton message="Categories" />
                    </Link>
                </li>
                <li>
                    <Link to="/youtube/s/channels/">
                        <RollingButton message="S Channels" />
                    </Link>
                </li>
                <li>
                    <Link to="/youtube/s/videos/latest/">
                        <RollingButton message="Latest Videos" />
                    </Link>
                </li>
                <li>
                    <Link to="/youtube/s/channels/latest/">
                        <RollingButton message="Latest Channels" />
                    </Link>
                </li>
                <li>
                    <Link to="/youtube/s/videos/">
                        <RollingButton message="S Videos" />
                    </Link>
                </li>
            </ul>
            <h2>Under Construction</h2>
        </div>
    )
}