import React, {useState} from "react";
import axiosInstance, {getCookie} from "../../x05Utils";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";
import RollingButton from "../../components/RollingButton";
import CSRFToken from "../../components/CSRFToken";
import { Helmet } from "react-helmet-async";

// https://blog.openreplay.com/async-data-fetching-with-react-select/
// https://www.youtube.com/watch?v=3u_ulMvTYZI

export default function BlogPostCreate() {
    const[title, setTitle] = useState();
    const[text0, setText0] = useState();
    const[text1, setText1] = useState();
    const[text2, setText2] = useState();
    const[text3, setText3] = useState();
    const[text4, setText4] = useState();
    const[text5, setText5] = useState();
    const[text6, setText6] = useState();
    const[text7, setText7] = useState();
    const[text8, setText8] = useState();
    const[text9, setText9] = useState();

    const[head0, setHead0] = useState();
    const[head1, setHead1] = useState();
    const[head2, setHead2] = useState();
    const[head3, setHead3] = useState();
    const[head4, setHead4] = useState();
    const[head5, setHead5] = useState();
    const[head6, setHead6] = useState();
    const[head7, setHead7] = useState();
    const[head8, setHead8] = useState();
    const[head9, setHead9] = useState();

    const[url0, setUrl0] = useState();
    const[url1, setUrl1] = useState();
    const[url2, setUrl2] = useState();
    const[url3, setUrl3] = useState();
    const[url4, setUrl4] = useState();
    const[url5, setUrl5] = useState();
    const[url6, setUrl6] = useState();
    const[url7, setUrl7] = useState();
    const[url8, setUrl8] = useState();
    const[url9, setUrl9] = useState();

    const[image0, setImage0] = useState();
    const[image1, setImage1] = useState();
    const[image2, setImage2] = useState();
    const[image3, setImage3] = useState();
    const[image4, setImage4] = useState();
    const[image5, setImage5] = useState();
    const[image6, setImage6] = useState();

    const[category, setCategory] = useState();
    const[categories, setCategories] = useState([""]);
    const location = useLocation();
    const navigate = useNavigate();

    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    let csrftoken = getCookie('csrftoken')


    React.useEffect(() => {
        const getData = async () => {
            const arr = []
            await axiosInstance.get(
                'blog/categories/'
                ).then((res) => {
                    if (res.status === 401) {
                        setLoggedIn(false)
                        setAuthUser(null)
                        localStorage.clear()
                        window.location.reload(false);
                    }
                    // always display categories even if 401
                    // console.log(res.data);
                    let result = res.data;
                    result.map((cate) => {
                        return arr.push({value: cate.name, label: cate.name})
                    })
                    setCategories(arr)
                    // console.log(arr)
                }
            )
        }
        getData();
    }, [setLoggedIn]);

    // handle selection
    const handleChange = value => {
        const cate = value[Object.keys(value)[0]];
        setCategory(cate);
    }


    async function postCreate(e) {
        e.preventDefault();
        //
        // use an axiosInstance call before post to refresh tokens if needed and avoid bug caused by image being lost in memory 
        await axiosInstance(
            'dead/'
        ).then((res) => {
            if (res.status === 401) {
                setLoggedIn(false)
                setAuthUser(null)
                console.log("401 was hit attempting token refresh")
                console.log(res.status)
                window.location.reload(false);
            }
        })
        // THIS API CALL IS ONLY USED AS A REFRESH TILL A FIX IS FOUND FOR IMAGE BUG
        //
        let form_data = new FormData();
        if (category){
            form_data.append("category", category)
        }
        if (title) {
            form_data.append("title", title)
        }
        if (text0) {
            form_data.append("text0", text0)
        }
        if (text1) {
            form_data.append("text1", text1)
        }
        if (text2) {
            form_data.append("text2", text2)
        }
        if (text3) {
            form_data.append("text3", text3)
        }
        if (text4) {
            form_data.append("text4", text4)
        }
        if (text5) {
            form_data.append("text5", text5)
        }
        if (text6) {
            form_data.append("text6", text6)
        }
        if (text7) {
            form_data.append("text7", text7)
        }
        if (text8) {
            form_data.append("text8", text8)
        }
        if (text9) {
            form_data.append("text9", text9)
        }
        if (head0) {
            form_data.append("head0", head0)
        }
        if (head1) {
            form_data.append("head1", head1)
        }
        if (head2) {
            form_data.append("head2", head2)
        }
        if (head3) {
            form_data.append("head3", head3)
        }
        if (head4) {
            form_data.append("head4", head4)
        }
        if (head5) {
            form_data.append("head5", head5)
        }
        if (head6) {
            form_data.append("head6", head6)
        }
        if (head7) {
            form_data.append("head7", head7)
        }
        if (head8) {
            form_data.append("head8", head8)
        }
        if (head9) {
            form_data.append("head9", head9)
        }
        if (url0) {
            form_data.append('url0', url0)
        }
        if (url1) {
            form_data.append('url1', url1)
        }
        if (url2) {
            form_data.append('url2', url2)
        }
        if (url3) {
            form_data.append('url3', url3)
        }
        if (url4) {
            form_data.append('url4', url4)
        }
        if (url5) {
            form_data.append('url5', url5)
        }
        if (url6) {
            form_data.append('url6', url6)
        }
        if (url7) {
            form_data.append('url7', url7)
        }
        if (url8) {
            form_data.append('url8', url8)
        }
        if (url9) {
            form_data.append('url9', url9)
        }
        if (image0) {
            form_data.append('blog_image0', image0, image0.name)
        }
        if (image1) {
            form_data.append('blog_image1', image1, image1.name)
        }
        if (image2) {
            form_data.append('blog_image2', image2, image2.name)
        }
        if (image3) {
            form_data.append('blog_image3', image3, image3.name)
        }
        if (image4) {
            form_data.append('blog_image4', image4, image4.name)
        }
        if (image5) {
            form_data.append('blog_image5', image5, image5.name)
        }
        if (image6) {
            form_data.append('blog_image6', image6, image6.name)
        }
        // console.log(form_data)
        await axios.post(
            '/api/blog/posts/create/', form_data, {
                // access token is needed in header too 
                headers: {
                  'Content-Type': 'multipart/form-data', 
                  Authorization: 'Bearer ' + localStorage.getItem('access'), 
                  'X-CSRFToken': csrftoken
                }
              }).then((res) => {
                if (res.status === 401) {
                    setLoggedIn(false);
                    setAuthUser(null)
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    navigate(
                        location?.state?.previousUrl
                            ? location.state.previousUrl
                            : '/blog/'
                    );
                }
            })
    }

    return(
        <div id="blog-post-create-container">
            <Helmet>
                <title>{`X-05 | Blog Post Create`}</title>
                <meta name="description" content="Effortlessly create compelling blog posts with our intuitive post creation page. Share your ideas, stories, and expertise with the world. Start crafting engaging content today!" />
            </Helmet>
            <h1 id="blog-post-create-title"><i>-</i> X-05 Blog Creation <i>-</i></h1>
            <hr></hr>
            { loggedIn ? <form onSubmit={postCreate}>
                <CSRFToken></CSRFToken>
                <label for="category-search">Category</label>
                <Select options={categories} onChange={handleChange} defaultOptions name="category-search" id="blog-post-create-search">
                </Select>
                <label for="title">Title</label>
                <input type="text" name="title" placeholder="" value={title} onChange={(e) => {
                    setTitle(e.target.value)
                }}>
                </input>
                <label for="head0">Head 0</label>
                <input type="text" name="head0" placeholder="" value={head0} onChange={(e) => {
                    setHead0(e.target.value)
                }}>
                </input>
                <label for="url0">URL 0</label>
                <input type="text" name="url0" placeholder="" value={url0} onChange={(e) => {
                    setUrl0(e.target.value)
                }}>
                </input>
                <label for="text0">Text 0</label>
                <textarea type="text" name="text0" placeholder="" value={text0} onChange={(e) => {
                    setText0(e.target.value)
                }}>
                </textarea>
                <label for="image0">Image 0</label>
                <input name="image0" type="file" className="blog-post-create-image" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                    setImage0(e.target.files[0])
                }}></input>
                <label for="head1">Head 1</label>
                <input type="text" name="head1" placeholder="" value={head1} onChange={(e) => {
                    setHead1(e.target.value)
                }}>
                </input>
                <label for="url1">URL 1</label>
                <input type="text" name="url1" placeholder="" value={url1} onChange={(e) => {
                    setUrl1(e.target.value)
                }}>
                </input>
                <label for="text1">Text 1</label>
                <textarea type="text" name="text1" placeholder="" value={text1} onChange={(e) => {
                    setText1(e.target.value)
                }}>
                </textarea>
                <label for="image1">Image 1</label>
                <input name="image1" type="file" className="blog-post-create-image" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                    setImage1(e.target.files[0])
                }}></input>
                <label for="head2">Head 2</label>
                <input type="text" name="head2" placeholder="" value={head2} onChange={(e) => {
                    setHead2(e.target.value)
                }}>
                </input>
                <label for="url2">URL 2</label>
                <input type="text" name="url2" placeholder="" value={url2} onChange={(e) => {
                    setUrl2(e.target.value)
                }}>
                </input>
                <label for="text2">Text 2</label>
                <textarea type="text" name="text2" placeholder="" value={text2} onChange={(e) => {
                    setText2(e.target.value)
                }}>
                </textarea>
                <label for="head3">Head 3</label>
                <input type="text" name="head3" placeholder="" value={head3} onChange={(e) => {
                    setHead3(e.target.value)
                }}>
                </input>
                <label for="url3">URL 3</label>
                <input type="text" name="url3" placeholder="" value={url3} onChange={(e) => {
                    setUrl3(e.target.value)
                }}>
                </input>
                <label for="text3">Text 3</label>
                <textarea type="text" name="text3" placeholder="" value={text3} onChange={(e) => {
                    setText3(e.target.value)
                }}>
                </textarea>
                <label for="image2">Image 2</label>
                <input name="image2" type="file" className="blog-post-create-image" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                    setImage2(e.target.files[0])
                }}></input>
                <label for="head4">Head 4</label>
                <input type="text" name="head4" placeholder="" value={head4} onChange={(e) => {
                    setHead4(e.target.value)
                }}>
                </input>
                <label for="url4">URL 4</label>
                <input type="text" name="url4" placeholder="" value={url4} onChange={(e) => {
                    setUrl4(e.target.value)
                }}>
                </input>
                <label for="text4">Text 4</label>
                <textarea type="text" name="text4" placeholder="" value={text4} onChange={(e) => {
                    setText4(e.target.value)
                }}>
                </textarea>
                <label for="image3">Image 3</label>
                <input name="image3" type="file" className="blog-post-create-image" accept="image/jpg,image/jpeg,image/png,image/gif" onChang={(e) => {
                    setImage3(e.target.files[0])
                }}></input>
                <label for="head5">Head 5</label>
                <input type="text" name="head5" placeholder="" value={head5} onChange={(e) => {
                    setHead5(e.target.value)
                }}>
                </input>
                <label for="url5">URL 5</label>
                <input type="text" name="url5" placeholder="" value={url5} onChange={(e) => {
                    setUrl5(e.target.value)
                }}>
                </input>
                <label for="text5">Text 5</label>
                <textarea type="text" name="text5" placeholder="" value={text5} onChange={(e) => {
                    setText5(e.target.value)
                }}>
                </textarea>
                <label for="head6">Head 6</label>
                <input type="text" name="head6" placeholder="" value={head6} onChange={(e) => {
                    setHead6(e.target.value)
                }}>
                </input>
                <label for="url6">URL 6</label>
                <input type="text" name="url6" placeholder="" value={url6} onChange={(e) => {
                    setUrl6(e.target.value)
                }}>
                </input>
                <label for="text6">Text 6</label>
                <textarea type="text" name="text6" placeholder="" value={text6} onChange={(e) => {
                    setText6(e.target.value)
                }}>
                </textarea>
                <label for="image4">Image 4</label>
                <input name="image4" type="file" className="blog-post-create-image" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                    setImage4(e.target.files[0])
                }}></input>
                <label for="head7">Head 7</label>
                <input type="text" name="head7" placeholder="" value={head7} onChange={(e) => {
                    setHead7(e.target.value)
                }}>
                </input>
                <label for="url7">URL 7</label>
                <input type="text" name="url7" placeholder="" value={url7} onChange={(e) => {
                    setUrl7(e.target.value)
                }}>
                </input>
                <label for="text7">Text 7</label>
                <textarea type="text" name="text7" placeholder="" value={text7} onChange={(e) => {
                    setText7(e.target.value)
                }}>
                </textarea>
                <label for="image5">Image 5</label>
                <input name="image5" type="file" className="blog-post-create-image" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                    setImage5(e.target.files[0])
                }}></input>
                <label for="head8">Head 8</label>
                <input type="text" name="head8" placeholder="" value={head8} onChange={(e) => {
                    setHead8(e.target.value)
                }}>
                </input>
                <label for="url8">URL 8</label>
                <input type="text" name="url8" placeholder="" value={url8} onChange={(e) => {
                    setUrl8(e.target.value)
                }}>
                </input>
                <label for="text8">Text 8</label>
                <textarea type="text" name="text8" placeholder="" value={text8} onChange={(e) => {
                    setText8(e.target.value)
                }}>
                </textarea>
                <label for="image6">Image 6</label>
                <input name="image6" type="file" className="blog-post-create-image" accept="image/jpg,image/jpeg,image/png,image/gif" onChange={(e) => {
                    setImage6(e.target.files[0])
                }}></input>
                <label for="head9">Head 9</label>
                <input type="text" name="head9" placeholder="" value={head9} onChange={(e) => {
                    setHead9(e.target.value)
                }}>
                </input>
                <label for="url9">URL 9</label>
                <input type="text" name="url9" placeholder="" value={url9} onChange={(e) => {
                    setUrl9(e.target.value)
                }}>
                </input>
                <label for="text9">Text 9</label>
                <textarea type="text" name="text9" placeholder="" value={text9} onChange={(e) => {
                    setText9(e.target.value)
                }}>
                </textarea>
                <RollingButton type="submit" message="Create"></RollingButton>
            </form> : <p>You're not logged in</p> }
        </div>
    )
}