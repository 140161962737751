import React from "react";
import axiosInstance from "../../x05Utils";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/LoginContext";
import { useUser } from "../../contexts/UserContext";

import RollingButton from "../../components/RollingButton";
import axios from "axios";
import { Helmet } from "react-helmet-async";

export default function About() {
    const [statement, setStatement] = React.useState();
    const [loggedIn, setLoggedIn] = useLogin();
    const [authUser, setAuthUser] = useUser();

    React.useEffect(() => {
        const getData = async () => {
            await axiosInstance.get(
                `misc/about/`
            ).then((res) => {
                if (res.status === 401) {
                    // Nav is handled by axiosinstance
                    setLoggedIn(false);
                    setAuthUser(null);
                    localStorage.clear();
                    window.location.reload(false);
                } else {
                    console.log(res.data)
                    setStatement(res.data);
                }
            })
        }
        getData();
    }, [])

    // Should be its own compenent
    const renderTextWithLinksAndBreaks = (text) => {
        // Check if text was provided
        if (!text) return null;
    
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        // const parts = text.split(urlRegex);
    
        const paragraphs = text.split('\\n');

        console.log(paragraphs);
        // loop through the paragraphs and remove any remaining \ characters
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i] = paragraphs[i].replace(/\\/g, '');
            // replace tabs with spaces here if needed
        }
        
        
    
        return paragraphs.map((paragraph, index) => (
            // we still need to remove the random \ from the text after replacing the \n characters
            // also if the text contains a link the \n characters are not replaced with <br> tags
            <React.Fragment key={index}>
                {index !== 0 && <br />} {/* Add <br> except for the first line */}
                {paragraph.split(urlRegex).map((part, partIndex) => {
                    if (part.match(urlRegex)) {
                        return (
                            <a key={partIndex} href={part} target="_blank" rel="noopener noreferrer">
                                {part}
                            </a>
                        );
                    }
                    return part;
                })}
            </React.Fragment>
        ));
    };

    return (
        <>
            <Helmet>
                <title>{`X-05 About Page`}</title>
                <meta name="description" content={statement?.statement.slice(0, 160)} />
            </Helmet>
            <div id="about-statement-container">
                <h1 id="about-statement-title"><i>-</i> About Page <i>-</i></h1>
                <hr></hr>
                <div id="about-statement-sub-data">
                    <p id="about-statement-date">Statement Implemented on - {statement?.created_at.slice(0, 10)}</p>
                    <p id="about-statement-user">By - {statement?.user_username}</p>
                </div>
                <hr></hr>
                <div id="about-statement-content">
                    <pre id="about-statement-text">{renderTextWithLinksAndBreaks(statement?.statement)}</pre>
                </div>
            </div>
        </>
    )
}